/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";



const IconBox = ({children, label, icon}) => {
  return (<div className={'icon-box'}>
    <div className={'icon-box-label'}>{label}</div>
    <div className={'icon-box-image'}>
      <img style={{width: '50%'}} alt="..." src={icon} />
    </div>
  </div>)
}

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="main landing-page">
        <div className="section">
          <div>
            <Row>
              <Col className="ml-auto mr-auto p-5" style={{backgroundColor: 'black'}} sm={12} md={6}>
                <div style={{maxWidth: 600, float: 'right'}}>
                  <h6 className={'mb-5'}>Mūsų sistemos privalumai KLIENTUI</h6>
                  <IconBox label={'Lokacija'} icon={require("assets/images/landing/icons/location.png")}/>
                  <div className={'sub-icon-box'}>
                    Mūsų sistemoje jūs turėsite galimybę surasti tinkamą trenerį pagal norimą lokaciją, laiką bei kitas
                    specifikacijas ir taip jau šią akimirką žengti pirmuosius žingsnius į sporto pasaulį.
                  </div>

                  <IconBox label={'Trenerių profiliai'} icon={require("assets/images/landing/icons/Group3.png")}/>
                  <div className={'sub-icon-box'}>
                    Susipažinkite su įvairių trenerių pasiekimais, patirtimi, darbo vietomis, gyvenimo stiliumi per
                    SPOTYA platformą jau dabar.
                  </div>


                  <IconBox label={'Greitos rezervacijos'} icon={require("assets/images/landing/icons/Group4.png")}/>
                  <div className={'sub-icon-box'}>
                    Pažindinimasis su treneriu nebėra būtinybė – bet kurią akimirką užėję į profilį, užsiregistruokite į
                    treniruotę jums tinkamu metu vos per kelias sekundes.
                  </div>


                  <IconBox label={'Tik jums suteikta paskyra'} icon={require("assets/images/landing/icons/Group5.png")}/>
                  <div className={'sub-icon-box'}>
                  Stebėkite savo turimas rezervacijas bei atšaukite jas jums patogiu metu.
                  </div>

                  <IconBox label={'Profesionalų sukurtos sporto programėalės'} icon={require("assets/images/landing/icons/Group6.png")}/>
                  <div className={'sub-icon-box'}>
                    Stebėkite savo turimas rezervacijas bei atšaukite jas jums patogiu metu.
                  </div>

                  <IconBox label={'Papildų nuolaidos suteikiamos'} icon={require("assets/images/landing/icons/Group7.png")}/>
                  <div className={'sub-icon-box'}>
                    Greitas ir paprastas treniruočių planavimas – ne vienintelis privalumas prisijungus prie mūsų sporto
                    bendruomenės. Mūsų partnerių produktai bus siūlomi už specialią, tik jums pritaikytą kainą.
                  </div>
                </div>
              </Col>
              <Col className="ml-auto mr-auto landing-right p-5" sm={12} md={6}
                   style={{backgroundRepeat: 'repeat-y', backgroundSize: '100%',  backgroundImage: "url(" + require("assets/images/landing/Fonas1.png") + ")",}}>
                <div style={{maxWidth: 600, float: 'left'}}>
                  <h6 className={'mb-5'}>Mūsų sistemos privalumai KLIENTUI</h6>
                  <IconBox label={'Lokacija'} icon={require("assets/images/landing/icons/location.png")}/>
                  <div className={'sub-icon-box'}>
                    Sistemoje patogiai suveskite visas savo darbo vietas/ lokacijas, kuriose sportuojate.
                  </div>

                  <IconBox label={'Trenerių profiliai'} icon={require("assets/images/landing/icons/Group3.png")}/>
                  <div className={'sub-icon-box'}>
                    Sistemoje patogiai suveskite visas savo darbo vietas/ lokacijas, kuriose sportuojate.
                  </div>


                  <IconBox label={'Greitos rezervacijos'} icon={require("assets/images/landing/icons/Group4.png")}/>
                  <div className={'sub-icon-box'}>
                    Pasinaudokite galimybe susikurti individualų grafiką su norimais laisvadieniais be jokių papildomų laiko derinimų su klientais.
                  </div>


                  <IconBox label={'Tik jums suteikta paskyra'}
                           icon={require("assets/images/landing/icons/Group8.png")}/>
                  <div className={'sub-icon-box'}>
                    Apsisaugokite nuo galimo klientų piktnaudžiavimo nesąžiningai nutraukiant rezervacijas.
                  </div>

                  <IconBox label={'Profesionalų sukurtos sporto programėalės'}
                           icon={require("assets/images/landing/icons/Group9.png")}/>
                  <div className={'sub-icon-box'}>
                    Apsisaugokite nuo galimo klientų piktnaudžiavimo nesąžiningai nutraukiant rezervacijas.
                  </div>

                  <IconBox label={'Papildų nuolaidos suteikiamos'}
                           icon={require("assets/images/landing/icons/Group10.png")}/>
                  <div className={'sub-icon-box'}>
                    Greitas ir paprastas treniruočių planavimas – ne vienintelis privalumas prisijungus prie mūsų sporto bendruomenės. Mūsų partnerių produktai bus siūlomi už specialią, tik jums pritaikytą kainą.
                  </div>
                </div>


              </Col>
            </Row>
          </div>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
