/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useEffect, useState} from "react";

// @mui material components
import Container from "@mui/material/Container";

// Material Dashboard 2 PRO React examples
import PageLayout from "views/examples/LayoutContainers/PageLayout";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
// Pricing page components
import Header from "./components/Header";
import Footer from "components/Footers/DemoFooter.js";
import PricingCards from "./components/PricingCards";
import MDBox from "components/MDBox";
import {API} from "../../../api";
const api = new API()


function PricingPage() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
       api.get('users/products').then(p=>setProducts(p||[]))
    }, []);

  return (
    <PageLayout>
      <Header>
          <ExamplesNavbar/>
        <Container>
          <PricingCards products={products} />
            <MDBox mb={5}></MDBox>
        </Container>
      </Header>
      <Footer />
    </PageLayout>
  );
}

export default PricingPage;
