/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useCallback, useMemo, useState} from "react";
import _ from "lodash";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Swal2 from "sweetalert2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDDropzone from "components/MDDropzone";
import SectionCarousel from "views/index-sections/SectionCarousel";

import {Container} from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";

import ProductImages from "views/examples/product-page/components/ProductImages";

import {API} from "api";
import {FormattedMessage, useIntl} from "react-intl";
const api = new API()

function Photos({form, setFormField, updateUser}) {
    const intl = useIntl()
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleLoading =  useCallback((b) => {
      setLoading(b)
  }, [setLoading])
  const onAdded =  useCallback((file) => {
      toggleLoading(true)
      console.log({...file})
  }, [form])

    const uploadFile = useCallback(async (file) => {
        console.log({...file}, form.certificates)
        const certificates = [...(form.certificates || []), {url: JSON.parse(file.xhr.response).url}]
        form.certificates=certificates;
        setFormField('certificates', certificates)

        //await updateUser(['photos'])
        toggleLoading(false)

    }, [form, updateUser, setFormField])

  const onComplete = useCallback((file) => {
      uploadFile(file)
  },[form, uploadFile])

  const onError =  useCallback((file, msg) => {
      toggleLoading(false)
      console.log(file)
      Swal2.fire('Error', msg, 'error')
  }, [form])

    const delPhoto = useCallback(async (url)=>{
        const certificates = [...(form.certificates.filter(f=>f.url!==url) || [])]
        setFormField('certificates', certificates)
        form.certificates = certificates
        setTimeout(async ()=>{
            //await updateUser(['photos'])
            toggleLoading(false)
        }, 250)
    }, [form])
  const deleteCbk = useCallback(async (url) => {
      toggleLoading(true)
      console.log(url)
      Swal2.fire({
          title: 'Do you want to delete this certificate?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
      }).then(async (result) => {
          if (result.isConfirmed) {
            await delPhoto(url)
          }
          toggleLoading(false)
      })
  }, [form])

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} >
          <Grid item>
              <MDTypography variant={'h5'} color={'dark'}><FormattedMessage id={"forms.certs"}/></MDTypography>
          </Grid>
          <Grid item xs={12}>
              {form.certificates.length > 0 ? <ProductImages items={form.certificates} deleteCbk={deleteCbk}/> : <MDTypography className={'text-center'} component={'div'} variant={'subtitle2'} color={'warning'}><FormattedMessage id={"forms.certs.no"}/></MDTypography>}
              {/*<SectionCarousel items={form.photos} />*/}
          </Grid>
          {!opened && <Grid item xs={12}><MDButton style={{float: 'right'}} onClick={()=>setOpened(o=>!o)} variant={'contained'} color={'success'}><FormattedMessage id={"forms.certs.add"}/></MDButton></Grid>}
          {opened && <Grid item xs={12}>
              {loading && <Container fluid={true} className={'p-5 text-center'}  ><PulseLoader color={'#9180ff'} size={18}/></Container>}
            <MDBox height="100%" mt={0.5} lineHeight={1} minWidth={'100%'}>
              <MDDropzone options={{
                dictDefaultMessage: intl.formatMessage({id: 'forms.photos.drop'}),
                maxFilesize: 20,
                createImageThumbnails: false,
                acceptedFiles: 'image/*',
                autoDiscover: false,
                disablePreviews: true,
                withCredentials: true,
                url: "/api/users/photos",
                autoProcessQueue: true,
                addedfile: onAdded,
                error: onError,
                complete: uploadFile
              }} />
            </MDBox>
          </Grid>}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Photos;
