/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useCallback, useEffect, useRef, useState} from "react";

// reactstrap components
import {
    Button,
    Label,
    FormGroup,
    Input,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col, Form, CustomInput, Collapse, Navbar,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import HeaderMap from "components/Headers/HeaderMap.js";
import Slider from "nouislider";
import "nouislider/dist/nouislider.min.css";


import Card from "@mui/material/Card";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Save';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PulseLoader from "react-spinners/PulseLoader";

import Calendar from "views/examples/Calendar";
import MDBox from "components/MDBox";
import BookingCalendar from "./BookingCalendar";

import tz from "moment-timezone";
import moment from "moment";
import Swal2 from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _ from "lodash";
import {API} from "api";
import {useAuth} from "useAuth";
import {MDDialog} from "components/Dialog";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Avatar from "@mui/material/Avatar";
import {blue} from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import MDBadge from "components/MDBadge";
import {FormattedMessage, useIntl } from "react-intl";
import {useDictionaries} from "lib/dictionaries";


const api = new API();

const ModalContent = ({event, list, setList}) => {
    useEffect(()=>{
        console.log(list)
    }, [list, event])
    const intl = useIntl()
    const {statusesMap} = useDictionaries()

    return (
        <List sx={{ pt: 0 }}>
            {list.map((attendee, i) => (
                <ListItem key={attendee.uuid}>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }} src={attendee.owner.avatar}>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${attendee.firstName} ${attendee.lastName}`} secondary={<>{intl.formatMessage({id: "forms.basic.email"})}: {attendee.email}<br/>{intl.formatMessage({id: "forms.basic.phone"})}: {attendee.phone} {attendee.comment?(<><br/>{intl.formatMessage({id: "forms.basic.comment"})}: {attendee.comment}</>):''}</>} />
                    <ListItemIcon>
                        {attendee.status === 'canceled' && <MDBox component={'div'} style={{height: 48}}><MDBadge color={'error'} size={'small'} badgeContent={intl.formatMessage({id: `dialog.canceled`})} /></MDBox>}
                        {attendee.status === 'declined' && <MDBox component={'div'} style={{height: 48}}><MDBadge color={'error'} size={'small'} badgeContent={intl.formatMessage({id: `dialog.declined`})} /></MDBox>}
                        {(attendee.status==='accepted' || attendee.status==='pending') && <FormControlLabel onChange={(_e, checked)=>{
                            console.log(checked, attendee.status)
                            attendee.status=(checked?'accepted':'pending')
                            list[i]=attendee
                            setList([...list])
                        }} color={attendee.status==='accepted'?'success':'dark'} control={<Switch size={'large'} color={'success'} checked={attendee.status==='accepted'}/>} label={statusesMap[attendee.status]?.label} />}
                    </ListItemIcon>
                </ListItem>
            ))}
        </List>
    )
}

function CalendarPage() {
    const Swal = withReactContent(Swal2);
    const [bookings, setBookings] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [filter, setFilter] = React.useState({});
    const [selected, setSelected] = React.useState(null);
    const [modalContent, setModalContent] = React.useState(null);
    const [opacity, setOpacity] = React.useState(0);
    const [minCardHeight, setMinCardHeight] = useState('100vh');
    let calendarRef = null
    const {user, fetchUser} = useAuth();
    const {statusesDict} = useDictionaries()
    const intl = useIntl()

    const fetchEvents= async () => {
        const sessions = await api.get(`sessions`) || [];
        setEvents(sessions.map(event=>({
            ...event,
            title: ``,
            start: `${event.startDate}T${(event.timeStart).toString().padStart(2, '0')}:00:00`,
            end: `${event.startDate}T${(event.timeEnd).toString().padStart(2, '0')}:00:00`,
            id: `${event.uuid}`,
            eventId: `${event.uuid}`,
            editable: false,
            display: 'background',
            className: `event-session`,
            isPreview: false,
            savedEvent: true,
        })))
    }

    const fetchBookings = async () => {
        const bookingsRes = await api.get(`bookings`) || [];
        const mappedBookings = bookingsRes.map(b=>({
            ...b,
            image: b.coach.avatar,
            start: `${b.startDate}T${(b.timeStart).toString().padStart(2, '0')}:00:00`,
            end: `${b.startDate}T${(parseInt(b.timeStart)+1).toString().padStart(2, '0')}:00:00`,
            id: `${b.session.uuid}-${b.uuid}`,
            eventId: `${b.session.uuid}-${b.timeStart}`,
            display: 'list-item',
            message: statusesDict.find(s=>s.status===b.status)?.message || b.status,
            className: `event-${statusesDict.find(s=>s.status===b.status)?.color}`
        }))
        console.log({mappedBookings})
        const groupedBookings = _.reduce(_.groupBy(mappedBookings, b=>b.eventId),(arr, b)=>{
            const item = arr.find(d=>d.eventId===b[0].eventId)
            if(!item) {
                const obj = {
                    ...b[0],
                    attendees: [b[0]],
                }
                arr.push(obj)
            } else {
                item.attendees.push(b[0])
            }
            return arr;
        }, [])
        console.log(groupedBookings)
        setBookings(groupedBookings)
    }

    useEffect(()=>{
        fetchEvents()
        fetchBookings()
    }, [])



    const setFilterField = (field, value) => {
        setFilter(f=>({...f, [field]: value}))
    }


    document.documentElement.classList.remove("nav-open");

    const updateEvents = useCallback((eventId, status)=>{
        const tempEvents = JSON.parse(JSON.stringify(events)).map(e=> {
            if (e.eventId === eventId) {
                console.log(e, eventId)
                e.status = status
                e.className = `event-${statusesDict.find(s=>s.status===status).color}`
            }
            return e;
        })
        console.log(events.find(e=>e.eventId===eventId),JSON.stringify(events)===JSON.stringify(tempEvents))
        //setEvents(tempEvents)
    }, [events])

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = async () => {
        await Promise.all(list.map(async(b)=>{
            await api.put(`bookings/${b.uuid}`, {status: b.status})
        }))
        fetchBookings()
        console.log('handleSave', selected, list)
        setOpen(false);
    };

    const [list, setList] = useState([])

    const showEventPopup = useCallback(({extendedProps}) => {
        setSelected(extendedProps)
        setList(extendedProps.attendees)
        handleClickOpen();
    }, [selected, list])
    useEffect(()=>{
        setModalContent(<ModalContent event={selected} list={list} setList={setList}/>)
    }, [list])

    return (
        <>
            <ExamplesNavbar/>
            <MDDialog
                title={intl.formatMessage({id: "calendar.modal.bookings"})}
                open={open}
                onClose={handleClose}
                onSave={handleSave}
                content={modalContent}
            />
            <div style={{height: '100%', marginTop: 140, minWidth: 410, minHeight: 17*60}} className={'px-0 px-sm-1 px-md-3 px-lg-5'}>
                <div  className={"my-5 px-0 px-lg-2 px-xl-5"}  style={{height: '100%', minHeight: 17*60}}>
                    <BookingCalendar coach={user} bookings={bookings} eventsArray={events} bookingClickHandler={showEventPopup}>
                        {statusesDict.filter(d=>!d.hidden).map(s=>(<span className={'m1-1'}><MDButton className={'legend-button'} color={s.color} variant={'contained'}>{_.capitalize(s.label)}</MDButton></span>))}
                    </BookingCalendar>
                </div>
            </div>
            <DemoFooter/>
        </>
    );
}

export default CalendarPage;
