import MDButton from "../../../components/MDButton";
import {FormattedMessage} from "react-intl";
import MDBox from "../../../components/MDBox";
import React from "react";
import {useNavigate} from "react-router-dom";

export const RegisterCTA = ({className='mx-auto'}) => {
    const navigate = useNavigate()
    return (
        <MDBox className={`${className}`} >
            <MDButton style={{height: 40}} variant={'gradient'} onClick={()=>navigate('/client/signup')} color="success">
                <FormattedMessage id={"search.signup"} />
            </MDButton>
        </MDBox>
    )
}
