/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const selectData = {
    gender: {
        en: [
            {label: "Male", id: "Male"},
            {label: "Female", id: "Female"}
        ],
        lt: [
            {label: "Vyras", id: "Male"},
            {label: "Moteris", id: "Female"}
        ]
    },
    birthDate: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    days: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
    ],
    years: [
        "1900",
        "1901",
        "1902",
        "1903",
        "1904",
        "1905",
        "1906",
        "1907",
        "1908",
        "1909",
        "1910",
        "1911",
        "1912",
        "1913",
        "1914",
        "1915",
        "1915",
        "1915",
        "1916",
        "1917",
        "1918",
        "1919",
        "1920",
        "1921",
        "1922",
        "1923",
        "1924",
        "1925",
        "1926",
        "1927",
        "1928",
        "1929",
        "1930",
        "1931",
        "1932",
        "1933",
        "1934",
        "1935",
        "1936",
        "1937",
        "1938",
        "1939",
        "1940",
        "1941",
        "1942",
        "1943",
        "1944",
        "1945",
        "1946",
        "1947",
        "1948",
        "1949",
        "1950",
        "1951",
        "1952",
        "1953",
        "1954",
        "1955",
        "1956",
        "1957",
        "1958",
        "1959",
        "1960",
        "1961",
        "1962",
        "1963",
        "1964",
        "1965",
        "1966",
        "1967",
        "1968",
        "1969",
        "1970",
        "1971",
        "1972",
        "1973",
        "1974",
        "1975",
        "1976",
        "1977",
        "1978",
        "1979",
        "1980",
        "1981",
        "1982",
        "1983",
        "1984",
        "1985",
        "1986",
        "1987",
        "1988",
        "1989",
        "1990",
        "1991",
        "1992",
        "1993",
        "1994",
        "1995",
        "1996",
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
    ],
    skills: ["react", "vue", "angular", "svelte", "javascript"],
    languages: ["Afrikaans",
        "Arabic",
        "Bengali",
        "Bulgarian",
        "Catalan",
        "Cantonese",
        "Croatian",
        "Czech",
        "Danish",
        "Dutch",
        "Lithuanian",
        "Malay",
        "Malayalam",
        "Panjabi",
        "Tamil",
        "English",
        "Finnish",
        "French",
        "German",
        "Greek",
        "Hebrew",
        "Hindi",
        "Hungarian",
        "Indonesian",
        "Italian",
        "Japanese",
        "Javanese",
        "Korean",
        "Norwegian",
        "Polish",
        "Portuguese",
        "Romanian",
        "Russian",
        "Serbian",
        "Slovak",
        "Slovene",
        "Spanish",
        "Swedish",
        "Telugu",
        "Thai",
        "Turkish",
        "Ukrainian",
        "Vietnamese",
        "Welsh",
        "Sign language",
        "Aramaic",
        "Armenian",
        "Berber",
        "Burmese",
        "Bosnian",
        "Brazilian",
        "Bulgarian",
        "Corsica",
        "Scottish",
        "Egyptian",
        "Esperanto",
        "Estonian",
        "Flemish",
        "Georgian",
        "Hawaiian",
        "Indonesian",
        "Irish",
        "Icelandic",
        "Latin",
        "Mandarin",
        "Nepalese",
        "Sanskrit",
        "Tagalog",
        "Tahitian",
        "Tibetan",
        "Gypsy",
        "Wu"],
    languageCodes: [
        {"name": "Afrikaans", "code": "af"},
        {"name": "Arabic", "code": "ar"},
        {"name": "Bengali", "code": "bn"},
        {"name": "Bulgarian", "code": "bg"},
        {"name": "Catalan", "code": "ca"},
        {"name": "Cantonese", "code": "yue"},  // Cantonese doesn't have a specific ISO 639-1 code, using "yue"
        {"name": "Croatian", "code": "hr"},
        {"name": "Czech", "code": "cs"},
        {"name": "Danish", "code": "da"},
        {"name": "Dutch", "code": "nl"},
        {"name": "Lithuanian", "code": "lt"},
        {"name": "Malay", "code": "ms"},
        {"name": "Malayalam", "code": "ml"},
        {"name": "Panjabi", "code": "pa"},
        {"name": "Tamil", "code": "ta"},
        {"name": "English", "code": "en"},
        {"name": "Finnish", "code": "fi"},
        {"name": "French", "code": "fr"},
        {"name": "German", "code": "de"},
        {"name": "Greek", "code": "el"},
        {"name": "Hebrew", "code": "he"},
        {"name": "Hindi", "code": "hi"},
        {"name": "Hungarian", "code": "hu"},
        {"name": "Indonesian", "code": "id"},
        {"name": "Italian", "code": "it"},
        {"name": "Japanese", "code": "ja"},
        {"name": "Javanese", "code": "jv"},
        {"name": "Korean", "code": "ko"},
        {"name": "Norwegian", "code": "no"},
        {"name": "Polish", "code": "pl"},
        {"name": "Portuguese", "code": "pt"},
        {"name": "Romanian", "code": "ro"},
        {"name": "Russian", "code": "ru"},
        {"name": "Serbian", "code": "sr"},
        {"name": "Slovak", "code": "sk"},
        {"name": "Slovene", "code": "sl"},
        {"name": "Spanish", "code": "es"},
        {"name": "Swedish", "code": "sv"},
        {"name": "Telugu", "code": "te"},
        {"name": "Thai", "code": "th"},
        {"name": "Turkish", "code": "tr"},
        {"name": "Ukrainian", "code": "uk"},
        {"name": "Vietnamese", "code": "vi"},
        {"name": "Welsh", "code": "cy"},
        {"name": "Sign language", "code": "sgn"}, // Sign languages generally use "sgn" plus a country code, e.g., "sgn-US" for ASL
        {"name": "Aramaic", "code": "arc"},
        {"name": "Armenian", "code": "hy"},
        {"name": "Berber", "code": "ber"}, // Collective code for Berber languages
        {"name": "Burmese", "code": "my"},
        {"name": "Bosnian", "code": "bs"},
        {"name": "Brazilian", "code": "pt"}, // Brazilian Portuguese doesn't have a separate ISO 639-1 code, "pt" is used
        {"name": "Bulgarian", "code": "bg"},
        {"name": "Corsica", "code": "co"},
        {"name": "Scottish", "code": "gd"},
        {"name": "Egyptian", "code": "arz"}, // Egyptian Arabic, no ISO 639-1 code
        {"name": "Esperanto", "code": "eo"},
        {"name": "Estonian", "code": "et"},
        {"name": "Flemish", "code": "nl"}, // Flemish is a variety of Dutch
        {"name": "Georgian", "code": "ka"},
        {"name": "Hawaiian", "code": "haw"},
        {"name": "Indonesian", "code": "id"},
        {"name": "Irish", "code": "ga"},
        {"name": "Icelandic", "code": "is"},
        {"name": "Latin", "code": "la"},
        {"name": "Mandarin", "code": "zh"},
        {"name": "Nepalese", "code": "ne"},
        {"name": "Sanskrit", "code": "sa"},
        {"name": "Tagalog", "code": "tl"},
        {"name": "Tahitian", "code": "ty"},
        {"name": "Tibetan", "code": "bo"},
        {"name": "Gypsy", "code": "rom"},
        {"name": "Wu", "code": "wuu"} // Wu Chinese doesn't have a specific ISO 639-1 code
    ]

};

export default selectData;
