import React, {useState, useContext, createContext, useEffect} from "react";
import {getCookie, setCookie} from "./cookies";
import enLocales from './lang/en.json'
import ltLocales from './lang/lt.json'

let globalContext;
export const appLocales = {
    en: enLocales,
    lt: ltLocales
}

export const LocaleContext = createContext({locale: 'en'});

export function ProvideLocale({ children }) {
    const localeData = useProvideLocale();
    return <LocaleContext.Provider value={localeData}>{children}</LocaleContext.Provider>;
}

const setGlobalContext = (context) => {
    globalContext = context;
};
export const getGlobalContext = () => globalContext;

export const useLocale = () => {
    return useContext(LocaleContext);
};


export const useGlobalContext = () => {
    const context  = useLocale();
    setGlobalContext(context);
    return context;
};

export function useProvideLocale() {

    const [locale, setLocale] = useState('lt')

    useEffect(() => {
        const saved = getCookie('spotya.lang');
        if(saved && locale !== saved){
            setLocale(saved);
        }
    }, []);

    useEffect(() => {
        if(locale) {
            setCookie('spotya.lang', locale);
        }
    }, [locale]);

    return {
        locale,
        setLocale,
    };
}
