/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useCallback, useEffect, useRef, useState} from "react";

import {v4} from "uuid";
import "nouislider/dist/nouislider.min.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Calendar from "views/examples/Calendar";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";

import Swal2 from 'sweetalert2'
import _ from "lodash";

import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import IconDateTime from "@mui/icons-material/DateRange";
import {useAuth} from "useAuth";
import {API} from "api";
import Select from 'react-select';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BadgeIcon from '@mui/icons-material/Badge';

import Moment from "moment";
import {extendMoment} from 'moment-range';
import Slider from "nouislider";
import {useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import Switch from "@mui/material/Switch";

const moment = extendMoment(Moment);

const api = new API()


const initialForm = {
    activity: null,
    location: null,
    employee: null,
    maxPrice: 200,
    minPrice: 0,
    timeStart: null,
    maxUsers: 1
}
export const SessionEditor = React.forwardRef(({
                                                   events,
                                                   setFilter,
                                                   isCreate,
                                                   isUpdate,
                                                   sessionId,
                                                   calendarRef,
                                                   setTempEvents,
                                                   next,
                                                   session,
                                                   coach
                                               }, ref) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [form, setForm] = useState(_.cloneDeep(initialForm));

    const setFormData = useCallback((data) => {
        setForm(data)
    }, [])
    React.useImperativeHandle(ref, () => ({
        setFormData, form
    }))
    const toHours = (v) => v.toString().padStart(2, '0') + ":00"
    const rangeOptions = (start, end) => {
        return _.range(start, end + 1).map(a => ({
            label: toHours(a),
            value: a
        })) || []
    }

    const tempEvent = (form) => ({
        ...form,
        title: 'Preview',
        start: `${form.startDate}T${(form.timeStart).toString().padStart(2, '0')}:00:00`,
        end: `${form.startDate}T${(form.timeEnd).toString().padStart(2, '0')}:00:00`,
        eventId: `${form.startDate}-${form.activity}`,
        status: 'pending',
        editable: true,
        display: 'list-item',
        className: `event-danger-dashed`,
        isPreview: true
    })

    React.useEffect(() => {
        if (
            document.getElementById("sliderDouble") &&
            !document.getElementById("sliderDouble").classList.contains("noUi-target")
        ) {
            Slider.create(document.getElementById("sliderDouble"), {
                start: [form.minPrice, form.maxPrice],
                connect: true,
                step: 1,

                range: {min: 0, max: 200},
                tooltips: [
                    {to: v => '€️' + v.toFixed()},
                    {to: v => '€️' + v.toFixed()}
                ],
            }).on('end', (values) => {
                console.log(values, form)
                setForm(f => ({...f, ['minPrice']: parseInt(values[0])}))
                setForm(f => ({...f, ['maxPrice']: parseInt(values[1])}))
            });
        }
    }, [setForm]);

    const {user, fetchUser} = useAuth();

    React.useEffect(() => {
        if (user) {
            const obj = {
                lastName: user.surname,
                firstName: user.name,
                email: user.email,
                phone: user.phone,
            };
            ['email', 'phone', 'lastName', 'firstName'].map((k) => {
                if (!form[k] && obj[k]) {
                    setForm(f => ({...f, [k]: obj[k]}))
                }
            })
        }
    }, [user])
    const [options, setOptions] = useState({
        activities: [{label: intl.formatMessage({id:'common.any'}), value: null}, ...(user?.dicts?.sports?.map(a => ({
            label: a.name[intl.locale],
            value: a.key
        })) || [])],
        employees: [{label: intl.formatMessage({id:'common.any'}), value: null}, ...(coach?.coachData?.employees?.map(a => ({
            label: a.name,
            value: a.id
        })) || [])],
        locations: [{label: intl.formatMessage({id:'common.any'}), value: null}, ...(coach?.coachData?.locations?.map(a => ({
            label: a.name,
            value: a.id
        })) || [])],
        startTimes: rangeOptions(6, 22),
        endTimes: rangeOptions(8, 23),
    });
    const [isLoading, setIsLoading] = useState(false);
    const updateCalendar = useCallback(_.debounce((f) => {
        setFilter(f)
    }, 1000), [])
    useEffect(() => {
        updateCalendar(form);
    }, [form])
    const createOption = (label, val = null) => ({
        label,
        value: val || v4(),
    });

    if (!user) {
        return null;
    }

    if (!coach) {
        return null;
    }

    const reqParams = [
        ['activity', 'employee', 'location', 'timeStart', 'startDate'],
        ['activity', 'employee', 'location', 'timeStart', 'firstName', 'lastName', 'email', 'phone']
    ]

    const handleSave = async () => {
        if (_.flatten(reqParams).filter(x => !form[x]).length < 1) {
            console.log(events)
            const event = events.find(e => e.activity === form.activity && e.location === form.location && e.startDate === form.startDate && parseInt(e.timeStart) <= parseInt(form.timeStart) && parseInt(e.timeEnd) >= parseInt(form.timeStart))

            if (event) {
                await api.post(`bookings`, {...form, coachId: coach.uuid, sessionId: event.uuid})
                setForm(_.cloneDeep(initialForm))
                setActiveStep(0)

                Swal2.fire({
                    title: intl.formatMessage({id: "forms.book.booked"}),
                    text: intl.formatMessage({id: "forms.book.sessionCreated"}, {name: coach.name}),
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#3085d6",
                    denyButtonColor: "#3085d6",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: "forms.book.confirmButtonText"}),
                    cancelButtonText: intl.formatMessage({id: "forms.book.cancelButtonText"}),
                    denyButtonText: intl.formatMessage({id: "forms.book.denyButtonText"})
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(`/client/bookings`)
                    } else if (result.isDenied) {
                        navigate(`/search`)
                    } else if (result.isDismissed) {
                        document.location.reload();
                    }
                });

                //Swal2.fire('Booked!', `Your session with ${options.employees.find(e=>e.value===form.employee).label} is created!`, 'success').then(()=>navigate(`/client/bookings`))
            } else {
                console.log('something wrong', form, event)
            }
        } else {
            Swal2.fire('Not all required fields are set', _.flatten(reqParams).filter(x => !form[x]).map(fld => _.capitalize(fld)).join(', '), 'warning')
        }
    }
    const handleBack = () => setActiveStep(activeStep - 1);

    const steps = [
        {
            label: intl.formatMessage({id: "forms.book.selectTimeSlot"}),
            Icon: IconDateTime,
        },
        {
            label: intl.formatMessage({id: "forms.book.confirm"}),
            Icon: BadgeIcon,
        }
    ]

    return (
        <>
            <Grid container spacing={10}  >
                <Grid item xs={12}>
                    <MDBox className={'w-100 booking-calendar-connector'}
                           sx={{
                               paddingTop: {
                                   xs: '25px !important'
                               }
                           }}
                    >
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map(({label, Icon}, i) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={() => <MDButton className={'stepper-btn'}
                                                                                  color={i === activeStep ? 'warning' : 'light'}
                                                                                  iconOnly><Icon/></MDButton>}
                                               StepIconProps={{
                                                   color: 'warning',
                                                   style: {marginTop: 10}
                                               }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </MDBox>
                </Grid>
                <Grid item xs={12}>
                    {activeStep === 0 && <Grid container spacing={3}>
                        <Grid item xs={12} lg={6} xl={4}>
                            <MDTypography variant="label"><FormattedMessage id={"forms.book.activity"}/></MDTypography>
                            <Select styles={{menu: (baseStyles, state) => ({...baseStyles, zIndex: 9999999999})}}
                                    options={options.activities}
                                    value={options.activities.find(a => form.activity === a.value)}
                                    onChange={(v) => {
                                        setForm(f => ({
                                            ...f,
                                            activity: v?.value || null,
                                            employee: null,
                                            location: null
                                        }))
                                        setTempEvents([])
                                        //setOptions(o=>({...o, activity: rangeOptions((v?.value||6)+1, 23)}))
                                    }}/>
                        </Grid>
                        {/*<Grid item xs={12} lg={6} xl={4}>
                            <MDTypography variant="label">Employee</MDTypography>
                            <Select
                                value={options.employees.find(a => form.employee === a.value)}
                                label="Coach"
                                options={options.employees}
                                onChange={(v) => setForm((f) => ({...f, employee: v?.value || null}))}
                            />
                        </Grid>*/}
                        <Grid item xs={12} lg={6} xl={4}>
                            <MDTypography variant="label"><FormattedMessage id={"forms.book.date"}/></MDTypography>
                            <MDDatePicker onChange={([v]) => setForm(f => ({
                                ...f,
                                startDate: moment(v || undefined).format('YYYY-MM-DD')
                            }))} value={form.startDate}
                                          options={{dateFormat: 'Y-m-d, l', minDate: moment().format('YYYY-MM-DD')}}
                                          input={{
                                              value: form.startDate,
                                              placeholder: "Select a Date",
                                              fullWidth: true
                                          }}/>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                            <MDTypography variant="label"><FormattedMessage id={"forms.book.location"}/></MDTypography>
                            <Select
                                value={options.locations.find(a => form.location === a.value)}
                                label="Location"
                                onChange={(v) => setForm((f) => ({...f, location: v?.value || null}))}
                                options={options.locations}
                            />
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>
                            <MDTypography variant="label"><FormattedMessage id={"forms.book.startTime"}/></MDTypography>
                            <Select styles={{menu: (baseStyles, state) => ({...baseStyles, zIndex: 9999999999})}}
                                    placeholder={intl.formatMessage({id: 'forms.book.select'})+'...'}
                                    options={options.startTimes}
                                    value={options.startTimes.find(a => form.timeStart === a.value)}
                                    onChange={(v) => setForm((f) => ({...f, timeStart: v?.value || null}))}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>

                            <MDTypography variant="label"><FormattedMessage id={"forms.book.priceRange"}/></MDTypography>
                            <div className={'p-3 mt-5'}>
                                <div className="slider slider-warning" id="sliderDouble"/>
                            </div>

                        </Grid>
                    </Grid>}
                    {activeStep === 1 &&
                        <Grid container spacing={5}>
                            <Grid item xs={0} lg={3}/>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6}>
                                        <MDInput type="text" label={intl.formatMessage({id: "forms.book.firstName"})} name={'firstName'} fullWidth
                                                 value={form.firstName}
                                                 onChange={e => setForm(f => ({...f, firstName: e.target.value}))}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MDInput type="text" label={intl.formatMessage({id: "forms.book.lastName"})} name={'lastName'} fullWidth
                                                 value={form.lastName}
                                                 onChange={e => setForm(f => ({...f, lastName: e.target.value}))}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MDInput type="text" label={intl.formatMessage({id: "common.email"})} name={'email'} fullWidth value={form.email}
                                                 onChange={e => setForm(f => ({...f, email: e.target.value}))}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            value={form.phone}
                                            onChange={v => setForm(f => ({...f, phone: v}))}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl>
                                            <MDTypography variant="label">{intl.formatMessage({id: "forms.book.firstSession"})} </MDTypography>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={form.firstTime}
                                                onChange={e => setForm(f => ({...f, firstTime: e.target.value}))}
                                            >
                                                <FormControlLabel value={intl.formatMessage({id: "common.yes"})} control={<Radio/>} label={intl.formatMessage({id: "common.yes"})}/>
                                                <FormControlLabel value={intl.formatMessage({id: "common.no"})} control={<Radio/>} label={intl.formatMessage({id: "common.no"})}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {form.maxUsers>1 && <Grid item xs={12} md={6}>
                                        <FormControl>
                                            <MDTypography variant="label">{intl.formatMessage({id: "forms.book.withFriend"})} </MDTypography>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={form.withFriend}
                                                onChange={e => setForm(f => ({...f, withFriend: e.target.value}))}
                                            >
                                                <FormControlLabel value={intl.formatMessage({id: "common.yes"})} control={<Radio/>} label={intl.formatMessage({id: "common.yes"})}/>
                                                <FormControlLabel value={intl.formatMessage({id: "common.no"})} control={<Radio/>} label={intl.formatMessage({id: "common.no"})}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>}
                                    <Grid item xs={12} md={6}>
                                        <MDInput type="text" multiline label={intl.formatMessage({id: "forms.book.notes"})}  rows={5}
                                                 name={'comment'} fullWidth value={form.comment}
                                                 onChange={e => setForm(f => ({...f, comment: e.target.value}))}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>}
                </Grid>
                {Object.values(_.pick(form, reqParams[0])).filter(v => v).length === reqParams[0].length && activeStep === 0 &&
                    <Grid item sm={12} mb={5}>
                        <MDBox style={{borderBottom: 'solid 1px silver', textAlign: 'right'}}>
                            <MDButton size={'large'} style={{marginBottom: 30}} variant={'contained'} color={'success'}
                                      onClick={() => setActiveStep(1)}>{intl.formatMessage({id: 'bookings.select.slot'})} <NavigateNextIcon
                                fontSize={'large'}/></MDButton>
                        </MDBox>
                    </Grid>}
                {activeStep === 1 && <Grid item sm={12} mb={5}>
                    <MDBox style={{borderBottom: 'solid 1px silver', textAlign: 'right'}}>
                        <MDButton size={'large'} style={{marginBottom: 30}} variant={'contained'} color={'light'}
                                  onClick={handleBack}><FormattedMessage id={"common.back"}/></MDButton> &nbsp;
                        <MDButton
                            disabled={Object.values(_.pick(form, reqParams[1])).filter(v => v).length !== reqParams[1].length}
                            size={'large'} style={{marginBottom: 30}} variant={'contained'} color={'success'}
                            onClick={handleSave}>{intl.formatMessage({id: "common.confirm"})}<NavigateNextIcon fontSize={'large'}/></MDButton>
                    </MDBox>
                </Grid>}
            </Grid>
        </>
    )
})


export function BookingCalendar({coach, bookings = [], eventsArray = [], bookingClickHandler, setLoading, children}) {
    const intl = useIntl()
    const [events, setEvents] = React.useState([]);
    const [tempEvents, setTempEvents] = React.useState([]);
    const [isCreate, setIsCreate] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [hideFull, setHideFull] = React.useState(false);
    const [filter, setFilter] = React.useState(false);
    const editorRef = useRef();
    const [selectedSession, setSelectedSession] = React.useState(null);
    const {user} = useAuth();
    const minCardHeight = '100vh';
    let calendarRef = null

    const getRef = (ref) => {
        calendarRef = ref;
    }

    const getEvents = useCallback(async () => {
        console.log('useCallback getEvents', filter)
        if (user && filter !== false) {
            setLoading(true)
            const newEvents = (await api.post(`sessions/${coach.uuid}`, {...(filter||{}), afterNow: true}))?.map(event => _.pick({
                ...event,
                title: '',
                start: `${event.startDate}T${(event.timeStart).toString().padStart(2, '0')}:00:00`,
                end: `${event.startDate}T${(event.timeEnd).toString().padStart(2, '0')}:00:00`,
                eventId: `${event.startDate}-${event.activity}`,
                editable: false,
                display: 'list-item',
                className: `event-session`,
                isPreview: false,
                savedEvent: true,
            }, ['uuid', 'bookings', 'isFull', 'maxUsers', 'savedEvent', 'price', 'startDate', 'endDate', 'timeStart', 'timeEnd', 'recurring', 'recurringId', 'recurringWeekAmount', 'activity', 'location', 'employee', 'title', 'start', 'end', 'eventId', 'status', 'editable', 'display', 'className', 'isPreview'])) || [];
            setEvents(newEvents)
            setLoading(false)
        }
    }, [user, filter])

    useEffect(() => {
        console.log(eventsArray)
        if (eventsArray && eventsArray.length > 0) {
            setEvents(eventsArray)
        }
    }, [eventsArray]);
    useEffect(() => {
        if (coach && !isCoach) {
            console.log('getEvents')
            getEvents();
        }
    }, [getEvents]);

    useEffect(() => {
        const calendarAPI = calendarRef?.getApi?.()
        if (calendarAPI) {
            calendarAPI.on('_resize', () => {
                const api = calendarRef?.getApi?.()
                //setMinCardHeight(api.currentData.currentViewType === "timeGridWeek"?'100vh':1280)
                api.currentData.currentViewType === "timeGridWeek" ? api.currentClassNames.push('week-view') : _.pull(api.currentClassNames, 'week-view')

            })
        }
    }, [calendarRef, getEvents])


    document.documentElement.classList.remove("nav-open");

    const renderSlotLabel = (slotLabel) => {
        return <div className={'slot-label'}>{slotLabel.text}</div>
    }
    const renderEvent = ({event}) => {
        //console.log('render', event.extendedProps.timeEnd, event.extendedProps.timeStart)
        const duration = (parseInt(event.extendedProps.timeEnd) - parseInt(event.extendedProps.timeStart)) || 1
        return (<div>{_.range(0, duration).map((i) => {

            const loc = _.find(coach.coachData?.locations, {id: event.extendedProps?.session?.location})?.name;
            if (event.extendedProps.isPreview) {
                return (

                    <MDBox key={i + 1000000} className={'mb-n11'}>
                        <MDBox className={'label-box p-1 text-danger'}>

                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                          variant={'overline'}>{moment(event.start).format("HH:mm")}<span
                                className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span></MDTypography>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, marginTop: 1}}
                                          variant={'h6'}>{event.title}</MDTypography>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                          variant={'overline'}>{_.find(user?.dicts?.sports || [], {key: event.extendedProps.activity})?.name[intl.locale]}</MDTypography>
                            {event.extendedProps.location && <MDTypography color={'inherit'} component={'div'}
                                                                           style={{
                                                                               lineHeight: 1,
                                                                               marginTop: -1,
                                                                               position: 'absolute'
                                                                           }}
                                                                           variant={'caption'}>@ {_.find(coach.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>}
                            <MDTypography color={'inherit'} component={'div'}
                                          style={{lineHeight: 1, right: 2, position: 'absolute'}}
                                          variant={'caption'}>{_.find(coach.coachData?.employees, {id: event.extendedProps.employee})?.name || coach.fullname}</MDTypography>

                        </MDBox>
                    </MDBox>
                )
            } else if (event.extendedProps.isOverlay) {
                return (
                    <MDBox key={i + 1000000} className={'w-100 h-100'} onClick={() => Swal2.fire({
                        position: "top-end",
                        icon: "error",
                        title: "This time is already booked",
                        showConfirmButton: false,
                        timer: 1500
                    })}></MDBox>
                )
            } else if (event.extendedProps.sessionId) {
                const sport=_.find(user.dicts?.sports, {key: event.extendedProps.session.activity})?.name[intl.locale];
                return (
                    <MDBox key={i + 1000000} className={'mb-n11 event-booking'} onClick={() => bookingClickHandler(event)}>

                        <MDTypography color={'inherit'} style={{lineHeight: 1.25, marginTop: 4}}
                                          variant={'button'} className={' trim-vertical'}>{moment(event.start).format("HH:mm")} {event.extendedProps.session.price && <span
                            className={'font-weight-bold'}> &euro;{event.extendedProps.session.price}</span>} <span style={{fontSize: 14-(sport.length/12)}}>{sport}</span>
                        </MDTypography>


                        <MDTypography className={'label-box-title'} color={'inherit'} component={'div'} style={{
                            lineHeight: 0.875,
                        }}
                                      variant={'button'}>
                            {event.extendedProps.location && <MDTypography color={'inherit'} component={'div'}
                                                                           className={'trim-vertical'}
                                                                           variant={'caption'}><FormattedMessage id={"schedule.location"}/>: {_.find(user.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>}
                            <MDTypography color={'inherit'} component={'div'}
                                          style={{lineHeight: 1}}
                                          variant={'caption'}><FormattedMessage id={"schedule.coach"}/>: {_.find(user.coachData?.employees, {id: event.extendedProps.employee})?.name}</MDTypography>

                            {event.extendedProps.session.maxUsers > 1 &&
                                <MDTypography color={'inherit'}
                                              onClick={() => bookingClickHandler(event)}
                                              variant={'caption'}><FormattedMessage id={"common.attendees.of"}/>: {event.extendedProps.attendees?.length || 0} <FormattedMessage id={"common.of"}/> {event.extendedProps.session.maxUsers}</MDTypography>
                            }
                            {event.extendedProps.message && <MDTypography color={'inherit'} className={'float-right'}
                                                                          onClick={() => bookingClickHandler(event)}
                                                                          variant={'caption'}>{event.extendedProps.message}</MDTypography>}
                        </MDTypography>
                    </MDBox>
                )
            } else if (event.extendedProps.savedEvent) {
                const numBookings = event.extendedProps.bookings?.filter(b => b.timeStart.toString() === (parseInt(event.extendedProps.timeStart) + i).toString()).length || 0

                return (

                    <MDBox key={i + 1000000} className={((event.extendedProps.maxUsers||1) - numBookings)?'mb-n11':'event-full'}
                           onClick={() => eventClickHandler({date: new Date(event.start.setHours(parseInt(event.extendedProps.timeStart) + i))})}>
                        <MDBox className={'label-box-saved p-1 text-dark'}>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, marginTop: -2}}
                                          variant={'overline'}>{moment(event.start.setHours(parseInt(event.extendedProps.timeStart) + i)).format("HH:mm")}<span
                                className={'d-none d-lg-inline'}> - {moment(event.start.setHours(parseInt(event.extendedProps.timeStart) + i + 1)).format("HH:mm")}</span>
                                <MDTypography className={'label-box-title'} color={'inherit'} component={'div'} style={{
                                    lineHeight: 1,
                                }}
                                              variant={'h6'}>{_.find(user.dicts?.sports, {key: event.extendedProps.activity})?.name[intl.locale]}
                                    {event.extendedProps.price && <span
                                        className={'font-weight-bold'}> &euro;{event.extendedProps.price}</span>}
                                    {event.extendedProps.location && loc && <span className={'small'}
                                    > ({loc})</span>}
                                </MDTypography>
                            </MDTypography>
                            <MDTypography color={'inherit'} component={'div'}
                                          style={{lineHeight: 1.4}}
                                          variant={'caption'}><FormattedMessage id={"calendar.coach"}/>: {_.find(coach.coachData?.employees, {id: event.extendedProps.employee})?.name || coach.fullname}</MDTypography>
                            <MDTypography color={'inherit'} component={'div'}
                                          style={{lineHeight: 1.2}}
                                          variant={'caption'}>{numBookings} <FormattedMessage id={"common.of"}/> {event.extendedProps.maxUsers} <FormattedMessage id={"common.attendees"}/></MDTypography>

                        </MDBox>
                    </MDBox>
                );
            } else {
                return null;
            }
        })}</div>);
    }

    const isCoach = React.useMemo(() => coach.uuid === user.uuid && !coach.isPreview, [user, coach])

    const getAllEvents = useCallback(() => {
        const fullBookings = []
        if (!isCoach) {
            for (let event of events) {
                let tbs = {}
                for (let bk of event.bookings) {
                    tbs[bk.timeStart] = (tbs[bk.timeStart] || 0) + 1
                    if (tbs[bk.timeStart] >= event.maxUsers) {
                        fullBookings.push({
                            ...bk,
                            start: `${event.startDate}T${(bk.timeStart).toString().padStart(2, '0')}:00:00`,
                            end: `${event.startDate}T${(parseInt(bk.timeStart) + 1).toString().padStart(2, '0')}:00:00`,
                            eventId: `${event.startDate}-${event.activity}`,
                            display: 'overlay',
                            className: `event-overlay ${hideFull?'full-booking-hidden':'full-booking'}`,
                            sessionId: true,
                            session: event,
                            isOverlay: true
                        })
                    }
                }
            }
            //console.log(fullBookings)
        }
        const returnRes = [...(events.filter(e => !e.uuid || e.uuid !== selectedSession)), ...(isCoach ? bookings : tempEvents), ...fullBookings].map(e => ({
            ...e,
            display: (!e.isPreview) ? e.display : 'block'
        }))
        //console.log('getAllEvents', events.length, tempEvents.length, bookings.length, returnRes)
        return returnRes
    }, [events, isCoach ? bookings : tempEvents, selectedSession, hideFull]);

    const dateClickHandler = useCallback(({date: start}) => {
        const calendarAPI = calendarRef?.getApi?.()
        if (calendarAPI) {
            if (calendarAPI.currentData.currentViewType !== 'timeGridWeek') {
                console.log(start)
                calendarAPI.changeView('timeGridWeek', start);
            }
        }
    }, [calendarRef]);
    const eventClickHandler = useCallback(({date: start}) => {

        const calendarAPI = calendarRef?.getApi?.()
        console.log('eventClickHandler', start)
        if (calendarAPI) {
            if (calendarAPI.currentData.currentViewType !== 'timeGridWeek') {
                console.log(start)
                calendarAPI.changeView('timeGridWeek', start);
            }
        }

        const event = hasEvents(start)[0]
        console.log(event)
        if (!event) {
            editorRef?.current?.setFormData?.({
                ...editorRef?.current?.form,
                timeStart: null,
                activity: null,
                employee: null,
                location: null
            })

            return setTempEvents([])
        } else {
            console.log('getBookingsLeft', getBookingsLeft(start))
            if (getBookingsLeft(start) < 1) {
                return Swal2.fire({
                    position: "top-end",
                    icon: "error",
                    title: "This time is already booked",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        }
        const startDate = moment(start).format('YYYY-MM-DD')
        const timeStart = moment(start).format('HH')
        const timeEnd = moment(start).add(1, 'h').format('HH')
        console.log({timeStart, timeEnd})
        setTempEvents([
            {
                ...event,
                title: user.fullname,
                start: `${startDate}T${(timeStart).toString().padStart(2, '0')}:00:00`,
                end: `${startDate}T${(timeEnd).toString().padStart(2, '0')}:00:00`,
                timeStart,
                timeEnd,
                id: `${event.uuid}-${startDate}-${filter.activity}`,
                eventId: `${event.uuid}-${startDate}-${filter.activity}`,
                status: 'pending',
                display: 'list-item',
                className: `event-danger-dashed`,
                isPreview: true,
                savedEvent: false
            }
        ])
        setTimeout(() => {
            if(!isCoach) {
                window.scrollTo(0, document.querySelector('.MuiGrid-container')?.getBoundingClientRect?.().top + 60)
                //setFilter(f => ({...f, timeStart: parseInt(timeStart)}))
                console.log(event)
                editorRef?.current?.setFormData?.({
                    ...editorRef?.current?.form,
                    startDate: event.startDate,
                    timeStart: parseInt(timeStart),
                    activity: event.activity,
                    employee: event.employee,
                    maxUsers: event.maxUsers,
                    location: event.location
                })
            }
        }, 100)
    }, [filter, events, setTempEvents, calendarRef, coach]);

    useEffect(() => {
        if (tempEvents[0]) {
            if (hasEvents(moment(`${tempEvents[0].startDate}T${(tempEvents[0].timeStart).toString().padStart(2, '0')}:00:00`)).length < 1) {
                editorRef?.current?.setFormData?.({
                    ...editorRef?.current?.form,
                    timeStart: null,
                    activity: null,
                    employee: null,
                    location: null
                })
                setTempEvents([])
            }
        }
    }, [events.length, tempEvents, editorRef])

    const getBookingsLeft = useCallback((date) => {
        const event = events.filter(e => moment(date).isBetween(`${e.startDate}T${(e.timeStart).toString().padStart(2, '0')}:00:00`, `${e.startDate}T${(e.timeEnd).toString().padStart(2, '0')}:00:00`, undefined, '[)'))?.[0]
        return (event.maxUsers || 1) - event?.bookings?.filter(b => b.timeStart.toString() === moment(date).format('H').toString()).length;
    }, [events, filter])

    const hasBookings = useCallback((date) => {
        const event = events.filter(e => moment(date).isBetween(`${e.startDate}T${(e.timeStart).toString().padStart(2, '0')}:00:00`, `${e.startDate}T${(e.timeEnd).toString().padStart(2, '0')}:00:00`, undefined, '[)'))?.[0]
        return event?.bookings?.filter(b => b.timeStart.toString() === moment(date).format('H').toString()).length >= (event.maxUsers || 1)
    }, [events, filter])

    const hasEvents = useCallback((date) => {
        return events.filter(e => moment(date).isBetween(`${e.startDate}T${(e.timeStart).toString().padStart(2, '0')}:00:00`, `${e.startDate}T${(e.timeEnd).toString().padStart(2, '0')}:00:00`, undefined, '[)'))
    }, [events, filter])


    return (
        <>
            <div style={{height: '100%', minHeight: 17 * 60}}>

                {coach && !isCoach &&
                    <SessionEditor ref={editorRef} calendarRef={calendarRef} setTempEvents={setTempEvents}
                                   next={console.log} coach={coach} setFilter={setFilter} events={events}/>}

                {coach?.uuid !== user?.uuid && <div className={'hide-full-switch'}>
                    <MDBox>
                        <Switch title={intl.formatMessage({id: 'bookings.only.available'})} color={'info'} checked={hideFull} onChange={()=>setHideFull(v=>!v)}/> {intl.formatMessage({id: 'bookings.only.available'})}
                    </MDBox>
                </div>}

                <Calendar
                    noCard={coach?.uuid !== user?.uuid}
                    cardHeight={minCardHeight}
                    themeSystem={'bootstrap5'}
                    events={getAllEvents()}
                    passRef={getRef}
                    header={{title: !isCoach ? "Select time slot" : intl.formatMessage({id: "calendar.header.coach"})}}
                    height={'100%'}
                    initialView={'timeGridWeek'}
                    allDaySlot={false}
                    dayMinWidth={200}
                    timeZone={'Europe/Vilnius'}
                    slotDuration={'01:00'}
                    slotMinTime={'06:00'}
                    slotMaxTime={'23:00'}
                    expandRows={true}
                    stickyHeaderDates={true}
                    slotMinWidth={200}
                    selectAllow={({start, end, ...rest}) => {
                        console.log(rest)
                        return hasEvents(start).length > 0 && getBookingsLeft(start) > 0 && Math.abs(start - end) === 3600000
                    }}
                    headerToolbar={{
                        start: 'title',
                        center: '',
                        end: 'dayGridMonth timeGridWeek today prev,next'
                    }}
                    slotLabelFormat={(date) => {
                        return `${date.start.hour.toString().padStart(2, '0')}:00　`
                    }}
                    eventContent={renderEvent}
                    slotLabelContent={renderSlotLabel}
                    dateClick={!isCoach ? eventClickHandler : dateClickHandler}
                    selectable={!isCoach}
                >{children}
                </Calendar>
            </div>
        </>
    );
}

export default BookingCalendar;
