/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props


// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import StarIcon from '@mui/icons-material/Star';
import {NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {useIntl} from "react-intl";


function MasterCard({image, title, pricing, rating, selected, coachId, location, actions, user}) {


    const intl = useIntl();

    return (
        <Card
            className={`m-auto ${selected && 'selected'}`}
            sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundColor: 'transparent',
            }}
            zIndex={0}
        >
            <MDBox
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                zIndex={1}
                sx={({palette: {gradients}, functions: {linearGradient}, boxShadows: {xl}}) => ({
                    background: selected?'linear-gradient(180deg, #42424a3d, rgba(182, 171, 249, 0.25))':'linear-gradient(180deg, #42424a3d, #42424a87,#42424abd, #000000)',
                    boxShadow: selected?'inset 0 0 30px 15px #B6ABF9':xl,
                    borderRadius: '0.75rem'
                })}
            />
            <MDBox position="relative" zIndex={2} p={2}>
                <MDBox sx={{left: 10,}} textAlign={'left'}>
                    <MDBadge className={'badge-card-marker'} color={'violet'} badgeContent={
                        <MDTypography variant="h6" color="white" fontWeight="medium">
                            {pricing || '25$ - 50$'}
                        </MDTypography>
                    } container/>
                </MDBox>
                <NavLink to={user?`/coach-profile/${coachId}`:'/client/signup'} tag={Link}>
                    <MDButton variant={'contained'} size={'small'} color={'success'} style={{
                        position: 'absolute',
                        top: 15,
                        right: 15
                    }}>{user?'Book':'SignUp To Book'}</MDButton>
                </NavLink>
                <MDBox className={'bottom-line'} width={'calc(100% - 33px)'} sx={{bottom: 10, position: 'fixed'}}>
                    <MDBox width={'100%'} display="flex" justifyContent="space-between" alignItems="space-between">
                        <MDTypography style={{marginTop: 5, maxWidth: '80%', textOverflow: 'ellipsis'}}
                                      className={"text-nowrap overflow-hidden"} variant="h6" color="white"
                                      fontWeight="medium">
                            {title}
                        </MDTypography>
                        <MDBadge color={'success'} className={'badge-card-marker'} badgeContent={
                            <MDBox width={'100%'} display="flex" justifyContent="space-between"
                                   alignItems="space-between">
                                <MDTypography variant="h6" className={'h6-bottom'} color="white" fontWeight="medium">
                                    <StarIcon color={'gold'} style={{marginTop: -4}}
                                              fontSize={'small'}/> {rating?parseFloat(rating).toFixed(1):intl.formatMessage({id: "common.noreviews"})}
                                </MDTypography>
                            </MDBox>
                        } container/>
                    </MDBox>

                </MDBox>
            </MDBox>
        </Card>
    );
}


export default MasterCard;
