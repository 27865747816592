/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import {useRef, useEffect} from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "../../../lib/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import scrollgridPlugin from "@fullcalendar/scrollgrid";
import listPlugin from "@fullcalendar/list";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for Calendar
import CalendarRoot from "./CalendarRoot";

// Material Dashboard 2 PRO React context
import {useMaterialUIController} from "context";

import enLocale from '@fullcalendar/core/locales/en-gb';
import ltLocale from '@fullcalendar/core/locales/lt';
import {useLocale} from "../../../useLocale";

function Calendar({header, cardHeight, passRef, children, noCard, ...rest}) {
    const [controller] = useMaterialUIController();
    const {locale} = useLocale();
    const {darkMode} = controller;
    const calendarRef = useRef(null)
    useEffect(() => calendarRef.current && passRef(calendarRef.current), [calendarRef, passRef])


    return (
        !noCard ? (<Card style={{height: '100%', marginTop: 100}} className={'px-1 px-sm-2 px-md-3 pb-4'}>
            <MDBox
                borderRadius="lg"
                shadow="md"
                height={60}
                top={-30}
                bgColor={'warning'}
                position="relative"
                zIndex={1}
            >
                <MDBox pt={header.title || header.date ? 2 : 0} textAlign={'center'} px={2} lineHeight={1}>
                    {header.title ? (
                        <MDTypography color={'white'} variant="h6" fontWeight="medium" textTransform="capitalize">
                            {header.title}
                        </MDTypography>
                    ) : null}
                </MDBox>
            </MDBox>
            {children ? (
                <MDBox className={'calendar-children-box'} p={3} mb={5} borderBottom={1}>
                    {children}
                </MDBox>
            ) : null}
            <CalendarRoot ownerState={{darkMode}}>
                <FullCalendar
                    {...rest}
                    locales={[ enLocale, {...ltLocale, noEventsText: 'Rezervacijų nerasta'} ]}
                    locale={locale}
                    schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin, scrollgridPlugin, listPlugin]}
                    firstDay={1}
                    ref={calendarRef}
                />
            </CalendarRoot>
        </Card>) : (<CalendarRoot ownerState={{darkMode}}>
            <FullCalendar
                {...rest}
                locales={[ enLocale, {...ltLocale, noEventsText: 'Rezervacijų nerasta'} ]}
                locale={locale}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin, scrollgridPlugin, listPlugin]}
                firstDay={1}
                ref={calendarRef}
            />
        </CalendarRoot>)
    );
}

export default Calendar;
