/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DefaultPricingCard from "views/examples/Cards/PricingCards/DefaultPricingCard";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function PricingCards({ products }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [starter, pro, premium] = ['starter', 'pro', 'premium'].map(k=>products.find(p=>p.metadata.id===k));

  return products?.length?(
    <MDBox position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            color={darkMode ? "dark" : "white"}
            badge={{ color: darkMode ? "warning" : "light", label: "starter" }}
            price={{ currency: "€", value: starter.price.unit_amount/100, type: "mėn" }}
            priceNew={{ value: starter.price.unit_amount/100 * (100-products.find(p=>p.name==='starter').coupon.percent_off)}}
            specifications={[
              { label: "Standartinė paskyra", includes: true },
              { label: "Būkite labiausiai pastebimas savo srities treneriu 1 mėn", includes: false },
              { label: "Online treniruočių vedimas", includes: false },
              { label: "Reklamuokite savo sporto programas/mitybos planus ar kitas norimas paslaugas*", includes: false },
            ]}
            action={{
              type: "internal",
              route: "/business/checkout/starter",
              color: darkMode ? "warning" : "dark",
              label: "select",
            }}
            shadow={darkMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            color="secondary"
            badge={{ color: "info", label: "pro" }}
            price={{ currency: "€", value: pro.price.unit_amount/100, type: "mėn" }}
            priceNew={{ value: starter.price.unit_amount/100 * (100-products.find(p=>p.name==='pro').coupon.percent_off)}}
            specifications={[
                { label: "Standartinė paskyra", includes: true },
                { label: "Būkite labiausiai pastebimas savo srities treneriu 1 mėn", includes: true },
                { label: "Online treniruočių vedimas", includes: false },
                { label: "Reklamuokite savo sporto programas/mitybos planus ar kitas norimas paslaugas*", includes: false },
            ]}
            action={{
              type: "internal",
              route: "/business/checkout/pro",
              color: "info",
              label: "select",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            color={"dark"}
            badge={{ color: "warning", label: "premium" }}
            priceNew={{ value: starter.price.unit_amount/100 * (100-products.find(p=>p.name==='premium').coupon.percent_off)}}
            price={{ currency: "€", value: premium.price.unit_amount/100, type: "mėn" }}
            specifications={[
                { label: "Standartinė paskyra", includes: true },
                { label: "Būkite labiausiai pastebimas savo srities treneriu 1 mėn", includes: true },
                { label: "Online treniruočių vedimas", includes: true },
                { label: "Reklamuokite savo sporto programas/mitybos planus ar kitas norimas paslaugas*", includes: true },
            ]}
            action={{
              type: "internal",
              route: "/business/checkout/premium",
              color: "warning",
              label: "select premium",
            }}
            shadow={darkMode}
          />
        </Grid>
      </Grid>
    </MDBox>
  ):null;
}

export default PricingCards;
