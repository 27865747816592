/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useCallback, useEffect, useRef, useState} from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";
import {v4} from "uuid";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "nouislider/dist/nouislider.min.css";


import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Calendar from "views/examples/Calendar";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";

import Swal2 from 'sweetalert2'
import _ from "lodash";

import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Tooltip from "@mui/material/Tooltip";

import IconService from "@mui/icons-material/SportsGymnastics";
import IconDateTime from "@mui/icons-material/DateRange";
import IconRecurring from "@mui/icons-material/EventRepeat";
import {useAuth} from "useAuth";
import {API} from "api";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Switch from "@mui/material/Switch";


import Moment from "moment";
import { extendMoment } from 'moment-range';
import MDInput from "components/MDInput";
import FormField from "views/examples/account/components/FormField";
import {FormattedMessage, useIntl} from "react-intl";
import withReactContent from "sweetalert2-react-content";


const moment = extendMoment(Moment);

const api = new API()


const initialForm = {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(3, 'month').format('YYYY-MM-DD'),
    timeStart: 6,
    timeEnd: 23,
    recurring: false,
    recurringWeekAmount: 1,
    maxUsers: 1,
    activity: null,
    location: null,
    employee: null,
    price: null,
}
export const SessionEditor = React.forwardRef(({isCreate, isUpdate, sessionId, calendarRef, setTempEvents, next, session}, ref) => {

    const {user, fetchUser} = useAuth();
    const [activeStep, setActiveStep] = useState(0);
    initialForm.employee = user.uuid;
    const [form, setForm] = useState(_.cloneDeep(initialForm));
    const toHours = (v) => v.toString().padStart(2, '0')+":00"
    const rangeOptions = (start, end) => {
        return _.range(start, end+1).map(a => ({
            label: toHours(a),
            value: a
        })) || []
    }

    const intl = useIntl()

    const tempEvent = (form) => ({
        ...form,
        title: 'Preview',
        start: `${form.startDate}T${(form.timeStart).toString().padStart(2, '0')}:00:00`,
        end: `${form.startDate}T${(form.timeEnd).toString().padStart(2, '0')}:00:00`,
        eventId: `${form.startDate}-${form.activity}`,
        status: 'pending',
        editable: true,
        display: 'list-item',
        className: `event-danger-dashed`,
        isPreview: true
    })

    useEffect(()=>{
        if((isCreate || isUpdate) && form.startDate && form.timeStart && form.timeEnd) {
            setTempEvents(form.recurring?Array.from(moment.range(form.startDate, form.endDate).by('weeks')).map(d=>tempEvent({...form, startDate: d.format('YYYY-MM-DD')})):[tempEvent(form)])
        }
    }, [form, isCreate, isUpdate, setTempEvents])

    const [options, setOptions] = useState({
        activities: user?.dicts?.sports?.map(a=>({
            label: a.name[intl.locale],
            value: a.key
        })) || [],
        employees: user?.coachData?.employees?.map(a=>({
            label: a.name,
            value: a.id
        })) || [],
        locations: user?.coachData?.locations?.map(a=>({
            label: a.name,
            value: a.id
        })) || [],
        startTimes: rangeOptions(6, 22),
        endTimes: rangeOptions(8, 23),
        maxUsers: _.range(1, 31).map(a => ({
            label: a,
            value: a
        })) || [],
    });
    const [isLoading, setIsLoading] = useState(false);

    const createOption = (label, val = null) => ({
        label,
        value: val || v4(),
    });

    const handleCreate = async (id, key, inputValue) => {
        setIsLoading(true);
        const newOption = createOption(inputValue);
        await api.post('users/add-option', {key, item: {id: newOption.value, name: newOption.label}})
        setIsLoading(false);
        await fetchUser()
        setOptions(o=>({...o, [key]: [...o[key], newOption]}));
        setForm(f=>({...f, [id]: newOption.value}));
    };

    React.useImperativeHandle(ref, () => ({
        setForm
    }))

    if(!user) {
        return null;
    }

    if(!isCreate && !isUpdate){
        return null;
    }

    const steps = [
        {
            label: intl.formatMessage({id: "schedule.selectSport"}),
            Icon: IconService,
            component: () => (<Grid container spacing={3}>
                <Grid item xs={12}>
                    <MDTypography variant="label"><FormattedMessage id={"schedule.sport"}/></MDTypography>
                    <CreatableSelect
                        noOptionsMessage={()=>'Type new item and press Enter to create'}
                        isClearable
                        placeholder={intl.formatMessage({id: "forms.book.select"})+'...'}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={(v) => setForm((f)=>({...f, activity: v?.value||null}))}
                        onCreateOption={(inputValue)=>handleCreate('activity', 'activities', inputValue)}
                        options={options.activities}
                        value={options.activities.find(a=>form.activity===a.value)}
                    />
                </Grid>
                <Grid item xs={12} className={'d-none'}>
                    <MDTypography variant="label"><FormattedMessage id={"schedule.coach"}/></MDTypography>
                    <CreatableSelect
                        placeholder={intl.formatMessage({id: "forms.book.select"})+'...'}
                        noOptionsMessage={()=>'Type new item and press Enter to create'}
                        isClearable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={(v) => setForm((f)=>({...f, employee: v?.value||null}))}
                        onCreateOption={(inputValue)=>handleCreate('employee', 'employees', inputValue)}
                        options={options.employees}
                        value={options.employees.find(a=>form.employee===a.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MDTypography variant="label"><FormattedMessage id={"schedule.location"}/></MDTypography>
                    <CreatableSelect
                        placeholder={intl.formatMessage({id: "forms.book.select"})+'...'}
                        noOptionsMessage={()=>'Type new item and press Enter to create'}
                        isClearable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={(v) => setForm((f)=>({...f, location: v?.value||null}))}
                        onCreateOption={(inputValue)=>handleCreate('location', 'locations', inputValue)}
                        options={options.locations}
                        value={options.locations.find(a=>form.location===a.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormField label={intl.formatMessage({id: "schedule.price"})} placeholder={intl.formatMessage({id: "schedule.price"})} value={form.price}
                               inputProps={{type: 'number', value: form.price, onChange: e => {
                                   const price = parseInt(e.target.value);
                                   console.log('price',price)
                                   setForm(f => ({...f, price}))
                               }}}/>
                </Grid>
                <Grid item xs={12}>
                    <MDTypography variant="label"><FormattedMessage id={"schedule.maximum"}/></MDTypography>
                    <Select styles={{menu: (baseStyles, state)=>({...baseStyles, zIndex: 9999999999})}}  options={options.maxUsers} value={createOption(form.maxUsers)} onChange={(v)=> {
                        setForm(f => ({...f, maxUsers: v?.value||1}))
                    }} />
                </Grid>
            </Grid>)
        }, {
            label: intl.formatMessage({id: "schedule.datetime"}),
            Icon: IconDateTime,
            component: () => (<Grid container spacing={3}>
        <Grid item xs={12}>
            <MDDatePicker onChange={([v])=>setForm(f => ({...f, startDate: moment(v|| undefined).format('YYYY-MM-DD')}))} onChange2={v=>setForm(f => ({...f, startDate: moment(v[0]).format('YYYY-MM-DD')}))} value={form.startDate} options={{dateFormat: 'Y-m-d, l',minDate: moment().format('YYYY-MM-DD') }} input={{ value: form.startDate, placeholder: "Select a Date", fullWidth: true }} />
        </Grid>
        <Grid item xs={12} lg={6}>
            <MDTypography variant="label"><FormattedMessage id={"schedule.start"}/></MDTypography>
            <Select styles={{menu: (baseStyles, state)=>({...baseStyles, zIndex: 9999999999})}}  options={options.startTimes} value={createOption(toHours(form.timeStart), form.timeStart)} onChange={(v)=> {
                setForm(f => ({...f, timeStart: v?.value||6}))
                setOptions(o=>({...o, endTimes: rangeOptions((v?.value||6)+1, 23)}))
            }} />
        </Grid>
        <Grid item xs={12} lg={6}>
            <MDTypography variant="label"><FormattedMessage id={"schedule.end"}/></MDTypography>
            <Select styles={{menu: (baseStyles, state)=>({...baseStyles, zIndex: 9999999999})}} options={options.endTimes} value={createOption(toHours(form.timeEnd), form.timeEnd)} onChange={(v)=> {
                setForm(f => ({...f, timeEnd: v?.value||22}))
                setOptions(o=>({...o, startTimes: rangeOptions(6, (v?.value||23)-1)}))
            }} />
        </Grid>
    </Grid>)
        }];
    if(!isUpdate) {
        steps.push({
            label: intl.formatMessage({id: "schedule.recurring"}),
            Icon: IconRecurring,
            component: () => (<Grid container spacing={3}>
                <Grid item xs={12}>
                    <Switch color={'secondary'} size={'large'} checked={form.recurring} onChange={e=>setForm(f => ({...f, recurring: !f.recurring}))} />
                    <MDTypography variant="label"><FormattedMessage id={"schedule.isRecurring"}/></MDTypography>
                    <div><small className={'text-muted'}><FormattedMessage id={"schedule.isRecurringComment"}/></small></div>
                </Grid>
                {form.recurring && <Grid item xs={12}>
                    <MDTypography variant="label"><FormattedMessage id={"schedule.endDate"}/></MDTypography>
                    <MDDatePicker onChange={([v])=>setForm(f => ({...f, endDate: moment(v|| undefined).format('YYYY-MM-DD')}))} onChange2={v=>setForm(f => ({...f, endDate: moment(v[0]).format('YYYY-MM-DD')}))} value={form.endDate} options={{dateFormat: 'Y-m-d, l',minDate: moment().add(1, 'week').format('YYYY-MM-DD') }} input={{ value: form.endDate, placeholder: "Select a Date", fullWidth: true }} />

                </Grid>}
            </Grid>)
        })
    }
    const isLastStep = activeStep === steps.length - 1;

    const reqParams = [
        ['activity','employee', 'location', 'price'],
        ['startDate', 'timeStart', 'timeEnd']
    ]
    const SwalReact = withReactContent(Swal2);
    const handleDelete = () => {
        let recur = false
        SwalReact.fire({
            title: intl.formatMessage({id: "common.areyousure"}),
            width: 600,
            html: <div>
                <h6>{intl.formatMessage({id: "common.irreversible"})}</h6>
                {form.recurringId && <div>
                    <hr/>
                   <strong className={'text-danger'}>
                       <Switch color={'warning'} size={'medium'}  onChange={e=>recur = !recur} />
                       {intl.formatMessage({id: "common.deleteall"})}
                   </strong>
                </div>}
            </div>,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: intl.formatMessage({id: "common.yes"}),
            cancelButtonText: intl.formatMessage({id: "schedule.cancel"})
        }).then((result) => {
            console.log({recur})
            if (result.isConfirmed) {
                api.delete(`sessions/${sessionId}${recur?`?recurring=true`:''}`);
                setForm(_.cloneDeep(initialForm));
                next();
                document.location.reload()
            }
        });
    }
    const handleNext = () => {
        if (!reqParams[activeStep] || reqParams[activeStep].filter(x => !form[x]).length < 1) {
            setActiveStep(activeStep + 1);
        } else {
            Swal2.fire('Not all required fields are set', reqParams[activeStep].filter(x => !form[x]).map(fld => _.capitalize(fld)).join(', '), 'warning')
        }
    }
    const handleSave = async () => {
        if(_.flatten(reqParams).filter(x => !form[x]).length<1) {
            isUpdate ? (await api.put(`sessions/${sessionId}`, form)) : (await api.post('sessions', form))
            setForm(_.cloneDeep(initialForm))
            setActiveStep(0)
            next()
        } else {
            Swal2.fire('Not all required fields are set', _.flatten(reqParams).filter(x => !form[x]).map(fld=>_.capitalize(fld)).join(', '),'warning')
        }
    }
    const handleBack = () => setActiveStep(activeStep - 1);

    return (
        <Container>
        <Grid container spacing={10}>
            <Grid item xs={12} sx={{display: {xs:'block', md:'none'}}}>
                <MDBox className={'w-100'}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(({label, Icon}, i) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={()=><MDButton className={'stepper-btn'} color={i===activeStep?'warning':'light'} iconOnly><Icon/></MDButton>} StepIconProps={{color: 'warning', style:{marginTop: 10}}}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </MDBox>
            </Grid>
            <Grid item sm={4} md={3} lg={2} sx={{display: {xs:'none', md:'block'}, minHeight: {xs:100, sm:400}}}>
                 <MDBox className={'m-auto h-100'}>
                     <Stepper activeStep={activeStep} orientation="vertical">
                         {steps.map(({label, Icon}, i) => (
                             <Step key={label}>
                                 <StepLabel StepIconComponent={()=><MDButton className={'stepper-btn'} color={i===activeStep?'warning':'light'} iconOnly><Icon/></MDButton>} StepIconProps={{color: 'warning', style:{marginTop: 10}}}>{label}</StepLabel>
                             </Step>
                         ))}
                     </Stepper>
                 </MDBox>
            </Grid>
            <Grid item sm={12} md={9} lg={10}>
         <MDBox component="form" pb={3} px={3} sx={{paddingRight: {xs:0}, paddingLeft: {xs: 0, sm:2, lg: 5, xl:3}}}>
             <MDTypography variant="h5">{steps[activeStep].label}</MDTypography>
             <hr/>
             {steps[activeStep].component?.()}
             <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                 {activeStep === 0 ? (
                     <MDButton variant="contained" color="warning" onClick={()=>{
                         if ((!isUpdate && reqParams[0].filter(x => !form[x]).length<3) || (isUpdate && JSON.stringify(form)!==JSON.stringify(_.pick(session, Object.keys(form))))) {
                             Swal2.fire({
                                 title: "Are you sure?",
                                 text: "You have unsaved changes.",
                                 icon: "warning",
                                 showCancelButton: true,
                                 confirmButtonColor: "#3085d6",
                                 cancelButtonColor: "#d33",
                                 confirmButtonText: "Yes"
                             }).then((result) => {
                                 if (result.isConfirmed) {
                                     setForm(_.cloneDeep(initialForm))
                                     next()
                                 }
                             });
                         } else {
                             setForm(_.cloneDeep(initialForm))
                             setActiveStep(0)
                             next()
                         }
                     }}>
                         <FormattedMessage id={"schedule.cancel"}/>
                     </MDButton>
                 ) : (
                     <MDButton variant="outlined" color="warning" onClick={handleBack}>
                         <FormattedMessage id={"schedule.back"}/>
                     </MDButton>
                 )}
                 {isUpdate && <MDButton variant="contained" color="error" onClick={handleDelete}>
                     {intl.formatMessage({id: 'common.delete'})}
                 </MDButton>}

                 <MDButton
                     variant="gradient"
                     color="warning"
                     onClick={!isLastStep ? handleNext : handleSave}
                 >
                     {isLastStep ? <FormattedMessage id={"schedule.save"}/> : <FormattedMessage id={"schedule.next"}/>}
                 </MDButton>
             </MDBox>
         </MDBox>
            </Grid>
     </Grid>
</Container>
    )
})


function CalendarPage() {
    const [events, setEvents] = React.useState([]);
    const [tempEvents, setTempEvents] = React.useState([]);
    const [isCreate, setIsCreate] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const editorRef = useRef();
    const [selectedSession, setSelectedSession] = React.useState(null);
    const {user} = useAuth();
    const minCardHeight = '100vh';
    let calendarRef = null

    const getRef = (ref) => {
        calendarRef = ref;
    }

    const getEvents = useCallback(async () => {
        if(user) {
            const newEvents = (await api.get('sessions'))?.map(event => _.pick({
                ...event,
                title: '',
                start: `${event.startDate}T${(event.timeStart).toString().padStart(2, '0')}:00:00`,
                end: `${event.startDate}T${(event.timeEnd).toString().padStart(2, '0')}:00:00`,
                eventId: `${event.startDate}-${event.activity}`,
                editable: false,
                display: 'list-item',
                className: `event-session`,
                isPreview: false,
                savedEvent: true,
            },['uuid','savedEvent', 'price','maxUsers','startDate', 'endDate', 'timeStart', 'timeEnd', 'recurring', 'recurringId', 'recurringWeekAmount', 'activity', 'location', 'employee', 'title', 'start', 'end', 'eventId', 'status', 'editable', 'display', 'className', 'isPreview'])) || [];
            //if (newEvents.length > 0 && JSON.stringify(newEvents) !== JSON.stringify(events)) {
                setEvents(newEvents)
            //}
        }
    }, [user])

    useEffect(()=>{
        const calendarAPI = calendarRef?.getApi?.()
        if(calendarAPI) {
            calendarAPI.on('_resize', ()=>{
                const api = calendarRef?.getApi?.()
                //setMinCardHeight(api.currentData.currentViewType === "timeGridWeek"?'100vh':1280)
                api.currentData.currentViewType === "timeGridWeek"?api.currentClassNames.push('week-view'):_.pull(api.currentClassNames, 'week-view')

            })
            getEvents();
        }
    }, [calendarRef, getEvents])

    const getAllEvents = useCallback(() => {
        return [...(events.filter(e=>!e.uuid || e.uuid!==selectedSession)), ...tempEvents]
    }, [events, tempEvents, selectedSession]);

    document.documentElement.classList.remove("nav-open");
    /*const setFilterField = (field, value) => {
        setFilter(f=>({...f, [field]: value}))
    }
    const setSelected = (v) => {
        setSelectedState(v);
    }

    const [filterOpen, setFilterOpen] = useState(false)

    const updateEvents = useCallback((eventId, status)=>{
        const tempEvents = JSON.parse(JSON.stringify(events)).map(e=> {
            if (e.eventId === eventId) {
                e.status = status
                e.className = `event-${statuses.find(s=>s.status===status).color}`
            }
            return e;
        })
        setEvents(tempEvents)
    }, [events])*/

    const renderSlotLabel = (slotLabel)=>{
        return <div className={'slot-label'}>{slotLabel.text}</div>
    }
    const renderEvent = ({event}) => {

        const duration = (parseInt(event.extendedProps.timeEnd) - parseInt(event.extendedProps.timeStart)) || 1
        return (<div style={{grid: '"a" 60px'}}>{_.range(0, duration).map((i) => {


        if(event._context.getCurrentData().currentViewType === "dayGridMonth"){
            return i===0?(
                <Tooltip componentsProps={{
                    tooltip: {className: 'bg-secondary'},
                    arrow: {className: 'text-secondary'},
                }} followCursor title={
                    <MDBox className={`p-1 ${event.extendedProps.isPreview?'label-box text-danger': 'bg-secondary text-light'}`}>
                        <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                      variant={'overline'}>{moment(event.start).format("HH:mm")}<span
                            className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span></MDTypography>
                        <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1.5}}
                                      variant={'h6'}>{_.find(user.dicts?.sports, {key: event.extendedProps.activity})?.name[intl.locale]}</MDTypography>
                        <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1.5}}
                                      variant={'overline'}>{_.find(user.coachData?.employees, {id: event.extendedProps.employee})?.name}</MDTypography>
                        {event.extendedProps.location && <MDTypography color={'inherit'} component={'div'}
                                                                       style={{lineHeight: 1,}}
                                                                       variant={'caption'}>@ {_.find(user.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>}
                    </MDBox>
                } >
                <MDBox className={`h-100 p-1 w-100 text-center ${event.extendedProps.isPreview && 'label-box'}`}>
                    <MDTypography component={'div'} style={{lineHeight: 1}} variant={'overline'}>{moment(event.start).format("HH:mm")}<span className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span></MDTypography>
                </MDBox>
                </Tooltip>
            ):null
        } else {

            if (event.extendedProps.isPreview) {
                return i===0?(
                    <Tooltip followCursor title={
                        <MDBox className={'p-1 text-danger'}>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1.5, marginTop: 1}}
                                          variant={'h6'}>Preview Session</MDTypography>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                          variant={'overline'}>
                                You can drag this session inside calendar or resize using bottom border.
                            </MDTypography>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, marginTop: 6}}
                                          variant={'overline'}>
                                To save the session, use form wizard at the top of the calendar.
                            </MDTypography>
                        </MDBox>
                    }>
                        <MDBox className={'mb-n1'}>
                            <MDBox className={'label-box p-1 text-danger'}>

                                <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                              variant={'overline'}>{moment(event.start).format("HH:mm")}<span
                                    className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span></MDTypography>
                                <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, marginTop: 1}}
                                              variant={'h6'}>{event.title}</MDTypography>
                                <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                              variant={'overline'}>{_.find(user.dicts?.sports, {key: event.extendedProps.activity})?.name[intl.locale]}</MDTypography>
                                {event.extendedProps.location && <MDTypography color={'inherit'} component={'div'}
                                                                               style={{
                                                                                   lineHeight: 1,
                                                                                   marginTop: -1,
                                                                                   position: 'absolute'
                                                                               }}
                                                                               variant={'caption'}>@ {_.find(user.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>}
                                <MDTypography color={'inherit'} component={'div'}
                                              style={{lineHeight: 1, right: 2, position: 'absolute'}}
                                              variant={'caption'}>{_.find(user.coachData?.employees, {id: event.extendedProps.employee})?.name}</MDTypography>

                            </MDBox>
                        </MDBox>
                    </Tooltip>
                ):null
            } else if (event.extendedProps.savedEvent) {
                return (
                    <Tooltip followCursor title={
                        <MDBox className={'label-box p-1 text-info'}>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                          variant={'overline'}>{moment(event.start).format("HH:mm")}<span
                                className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span></MDTypography>
                            <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1.5}}
                                          variant={'h6'}>{_.find(user.dicts?.sports, {key: event.extendedProps.activity})?.name[intl.locale]}</MDTypography>
                            <MDTypography color={'inherit'} component={'div'}
                                          style={{lineHeight: 1}}
                                          variant={'body'}>{_.find(user.coachData?.employees, {id: event.extendedProps.employee})?.name}</MDTypography>
                            {event.extendedProps.location && <MDTypography color={'inherit'} component={'div'}
                                                                           style={{lineHeight: 1.5}}
                                                                           variant={'caption'}>@ {_.find(user.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>}
                        </MDBox>
                    }>
                        {i<1000?(<MDBox className={'mb-n1 '}>
                            <MDBox className={'label-box-saved p-1 text-dark'}>
                                <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, marginTop: -2}}
                                              variant={'overline'}>{moment(event.start.setHours(parseInt(event.extendedProps.timeStart) + i)).format("HH:mm")} &nbsp; {event.extendedProps.price &&
                                    <span
                                        className={'font-weight-bold'}> &euro;{event.extendedProps.price}</span>}
                                </MDTypography>
                                <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, fontSize: 14}}
                                              variant={'h6'} className={'trim-vertical'}>{_.find(user.dicts?.sports, {key: event.extendedProps.activity})?.name[intl.locale]}</MDTypography>
                                {event.extendedProps.location && <MDTypography color={'inherit'} component={'div'}
                                                                               style={{height: '1rem' }} className={'trim-vertical'}
                                                                               variant={'caption'}><FormattedMessage id={"schedule.location"}/>: {_.find(user.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>}
                                <MDTypography color={'inherit'} component={'div'}
                                              style={{lineHeight: 1.2}}
                                              variant={'caption'}><FormattedMessage id={"schedule.coach"}/>: {_.find(user.coachData?.employees, {id: event.extendedProps.employee})?.name}</MDTypography>

                            </MDBox>
                        </MDBox>):(<MDBox className={'mb-n1'}>
                            <MDBox className={'label-box-saved p-1 text-dark '} style={{position: 'relative'}}>
                                <MDTypography color={'inherit'} style={{position: 'absolute', top: 'calc(50% - .64em)'}} component={'div'}
                                              variant={'caption'}>{_.find(user.dicts?.sports, {key: event.extendedProps.activity})?.name[intl.locale]}
                                &nbsp;
                              {moment(event.start.setHours(parseInt(event.extendedProps.timeStart) + i)).format("HH:mm")}
                                &nbsp;
                                {event.extendedProps.location && _.find(user.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>

                            </MDBox>
                        </MDBox>)}
                    </Tooltip>
                );
            } else {
                return null;
            }
        }
        })}</div>)
    }

    const handleFinalize = useCallback(async ()=>{
        console.log('handleFinalize')
        setTempEvents([])
        setEvents([])
        setSelectedSession(null)
        editorRef?.current?.setForm?.(_.cloneDeep(initialForm))
        setIsCreate(false);
        setIsUpdate(false);
        setSelectedSession(null);
        getEvents();
    }, [editorRef, getEvents])

    const eventClickHandler = ({event}) => {
        if(event._context.getCurrentData().currentViewType === "timeGridWeek")
        if(!isUpdate && !isCreate) {
            setSelectedSession(event.extendedProps.uuid)
            setIsUpdate(true)
            console.log(event.extendedProps)
            if(editorRef?.current?.setForm) {
                editorRef.current.setForm(f =>{
                    Object.keys(f).map(k=>{
                        return f[k] = event.extendedProps[k]
                    })
                    console.log(event.extendedProps)
                    if(event.extendedProps.recurringId)
                        f.recurringId = event.extendedProps.recurringId;
                    return {...f}
                })
            }
        }
    }
    const eventDropHandler = ({oldEvent: oEvent, event, delta}) => {
        let oldEvent = {...oEvent};
        if(editorRef?.current?.setForm) {
            editorRef.current.setForm(f => {
                if(delta.days){
                    oldEvent.start=moment(oEvent.start).add(delta.days, 'days').toDate()
                    oldEvent.end=moment(oEvent.end).add(delta.days, 'days').toDate()
                    f.startDate = moment(oldEvent.start).format('YYYY-MM-DD')


                    let newStart = moment(oEvent.start).add(delta)
                    if(newStart.isBefore(oldEvent.start, 'date') || newStart.isBefore(moment(oldEvent.start).hour(6), 'hour')) {
                        newStart.date(moment(oldEvent.start).date()).hour(6);
                    }
                    let newEnd = moment(oEvent.end).add(delta, 'ms')
                    if(newEnd.isAfter(oldEvent.end, 'date') || newEnd.isAfter(moment(oldEvent.end).hour(23), 'hour')) {
                        newEnd.date(moment(oldEvent.end).date()).hour(23);
                    }
                    return {...f, timeEnd: newEnd.hour(), timeStart: newStart.hour()}
                } else {
                    oldEvent = _.cloneDeep(oEvent)
                    let newStart = moment(oEvent.start).add(delta)
                    if(newStart.isBefore(oldEvent.start, 'date') || newStart.isBefore(moment(oldEvent.start).hour(6), 'hour')) {
                        newStart.date(moment(oldEvent.start).date()).hour(6);
                    }
                    let newEnd = moment(oEvent.end).add(delta, 'ms')
                    if(newEnd.isAfter(oldEvent.end, 'date') || newEnd.isAfter(moment(oldEvent.end).hour(23), 'hour')) {
                        newEnd.date(moment(oldEvent.end).date()).hour(23);
                    }
                    return {...f, timeEnd: newEnd.hour(), timeStart: newStart.hour()}
                }
            })
        }
    }
    const eventResizeHandler = ({oldEvent, endDelta}) => {
        if(editorRef?.current?.setForm) {
            editorRef.current.setForm(f => {
                let newEnd = moment(oldEvent.end).add(endDelta)
                if(newEnd.isAfter(oldEvent.end, 'day') || newEnd.isAfter(moment(oldEvent.end).hour(23), 'hour')) {
                    newEnd.date(moment(oldEvent.end).date()).hour(23);
                }
                return {...f, timeEnd: newEnd.hour()}
            })
        }
    }
    const intl = useIntl()


    return (
        <>
            <ExamplesNavbar/>
            <div style={{height: '100%', marginTop: 140, minWidth: 410, minHeight: 17*60}} className={'px-0 px-sm-1 px-md-3 px-lg-5'}>
                <div  className={"my-5 px-0 px-lg-2 px-xl-5 "}  style={{height: '100%', minHeight: 17*60}}>
                    <Calendar
                        cardHeight={minCardHeight}
                        themeSystem={'bootstrap5'}
                        events={getAllEvents()}
                        passRef={getRef}
                        header={{title: intl.formatMessage({id: "schedule.header"})}}
                        height={'100%'}
                        initialView={'timeGridWeek'}
                        allDaySlot={false}
                        dayMinWidth={200}
                        timeZone={'Europe/Vilnius'}
                        slotDuration={'01:00'}
                        slotMinTime={'06:00'}
                        slotMaxTime={'23:00'}
                        expandRows={true}
                        stickyHeaderDates={true}
                        slotMinWidth={200}
                        headerToolbar={{
                            start: 'title',
                            center: '',
                            end: 'dayGridMonth timeGridWeek today prev,next'
                        }}
                        slotLabelFormat={(date)=>{
                            return `${date.start.hour.toString().padStart(2,'0')}:00 - ${(date.start.hour+1).toString().padStart(2,'0')}:00`
                        }}
                        eventContent={renderEvent}
                        slotLabelContent={renderSlotLabel}
                        eventDrop={eventDropHandler}
                        eventResize={eventResizeHandler}
                        eventClick={eventClickHandler}
                    >
                        {!isCreate && !isUpdate && <MDButton variant={'contained'} color={'success'} onClick={()=>setIsCreate(true)}><FormattedMessage id={"schedule.create"}/></MDButton>}
                        <SessionEditor ref={editorRef} isCreate={isCreate} isUpdate={isUpdate} next={handleFinalize} sessionId={selectedSession} session={selectedSession && events.find(s=>s.uuid===selectedSession)} calendarRef={calendarRef} setTempEvents={setTempEvents} />
                    </Calendar>
                </div>

            </div>


            <DemoFooter/>
        </>
    );
}

export default CalendarPage;
