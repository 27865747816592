/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import VerificationInput from "react-verification-input";

// Authentication layout components
import CoverLayout from "./components/CoverLayout";
import {useAuth} from "../../../useAuth";
import {FormattedMessage, useIntl} from "react-intl";

// Images
import bgImage from "assets/images/26727090.jpg";
import Swal2 from "sweetalert2";
import Swal from "sweetalert2";

function Cover() {
    const intl = useIntl()
    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [code, setCode] = useState(null);
    const [password, setPassword] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [showResend, setShowResend] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('hash');
    const auth = useAuth();

    return (
        <CoverLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                       <FormattedMessage id={"common.reset"}/> <FormattedMessage id={"common.password"}/>
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter your new password
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput disabled={sent}
                                     fullWidth
                                     label={intl.formatMessage({id: "common.password"})}
                                     variant="standard"
                                     value={password}
                                     name={'pw1'}
                                     onChange={e => setPassword(e.target.value)}
                                     inputProps={{type: "password", autoComplete: "new-password"}}
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput disabled={sent}
                                     fullWidth
                                     label="Repeat Password"
                                     variant="standard"
                                     value={password2}
                                     name={'pw2'}
                                     onChange={e => setPassword2(e.target.value)}
                                     inputProps={{type: "password", autoComplete: "new-password"}}
                            />
                        </MDBox>

                        <MDButton variant="gradient" color="info" fullWidth onClick={async ()=> {
                            if(password && password2) {
                                if(password===password2) {
                                    auth.confirmPasswordReset(hash, password, (user) => document.location.href = user.isBusiness ? '/business/login' : '/client/login')
                                } else {
                                    Swal.fire({
                                        title: '',
                                        text: `Passwords does not match`,
                                        icon: 'warning',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            } else {
                                Swal.fire({
                                    title: '',
                                    text: `Please fill in both Password fields`,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                        }}>Submit</MDButton>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default Cover;
