/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useState, useEffect, useCallback} from "react";

// react-images-viewer components
import ImgsViewer from "../../../../../lib/react-images-viewer/src/ImgsViewer";

// @mui material components
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function ProductImages({items=[], deleteCbk}) {
  const [currentImage, setCurrentImage] = useState(items[0]);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(items[Number(currentTarget.id)]);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  useEffect(()=>{
      if(!currentImage && items[0])setCurrentImage(items[0])
  }, [items])

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  return (
    <MDBox>
      <ImgsViewer
        imgs={items.map(item=>({key: item.url, src: item.url, srcSet:[item.url], caption: item.caption||''}))}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />

        {currentImage && <MDBox
        component="img"
        src={currentImage.url}
        alt="Product Image"
        shadow="lg"
        borderRadius="lg"
        width="100%"
        style={{maxHeight: 600}}
        sx={{ cursor: "pointer", objectFit: "contain", backgroundSize: 'auto 100%' }}
        onClick={openImgsViewer}
      />}
      <MDBox mt={2} pt={1} style={{maxWidth: '100%', overflowX: 'auto'}}>
        <Stack direction="row" spacing={3}>
            {items.map((item, i)=>( <MDBox position={'relative'}
                                           key={i}>
                <MDBox
                component="img"
                id={i}
                src={item.url}
                alt={item.caption || item.url}
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="5rem"
                minHeight="5rem"
                maxWidth={'7.5rem'}
                minWidth={'5rem'}
                sx={{ cursor: "pointer", objectFit: "contain", backgroundSize: 'auto 100%'}}
                onClick={handleSetCurrentImage}
            />
                <MDButton className={'del-img-btn '} style={{
                    position: 'absolute',
                    top: 2,
                    right: 2,
                    opacity: 0.9
                }} iconOnly size={'small'} variant={'gradient'} color={'error'} onClick={()=>deleteCbk(item.url)}><DeleteForeverIcon fontSize={'large'}/></MDButton>
            </MDBox>))}
        </Stack>
      </MDBox>
    </MDBox>
  );
}

export default ProductImages;
