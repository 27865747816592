/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import {useIntl} from "react-intl";

function LandingPage() {
  const intl = useIntl()
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />

      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Let's talk product</h2>
                <h5 className="description">
                  ...
                </h5>
              </Col>
            </Row>

            <div className={'mt-5'}>&nbsp;</div>
            <div className={'mt-5'}>&nbsp;</div>
            <div className={'mt-5'}>&nbsp;</div>
            <div className={'mt-5'}>&nbsp;</div>
            <div className={'mt-5'}>&nbsp;</div>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Let's talk about us</h2>
            <h5 className="description">
              ...
            </h5>
            <div className={'mt-5'}>&nbsp;</div>
            <div className={'mt-5'}>&nbsp;</div>
          </Container>
        </div>
      </div>
      <DemoFooter/>
    </>
  );
}

export default LandingPage;
