/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";

// Material Dashboard 2 PRO React examples
import PageLayout from "views/examples/LayoutContainers/PageLayout";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
// Pricing page components
import Header from "./components/Header";
import Footer from "components/Footers/DemoFooter.js";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import {loadStripe} from '@stripe/stripe-js';
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {API} from "api";
import Swal2 from "sweetalert2";
import {FormattedNumber} from "react-intl";
import MDButton from "components/MDButton";
const stripePromise = loadStripe('pk_live_51OqclyGin03VTWox9BRxHE7pvd9qGGUtjAt7ogPDCb8YsbX4CqGsaMMKWivhN6rPm8fZuP1yCxuD8YhxGoRDLclm00BPmcjZyC');

const api = new API()

const CheckoutForm = ({params, plan}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (!stripe) {
            return;
        }
        console.log(stripe)
    }, [stripe]);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const {error, ...rest} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: "if_required"
        });


        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            if(rest.paymentIntent?.status==='succeeded') {
                Swal2.fire({
                    icon:"success",
                    showCancelButton: false,
                }).then(()=>{
                    setTimeout(()=>document.location.href='/business/profile', 1500)
                })
            }
        }
    };


    return plan?(
        <form onSubmit={handleSubmit}>
            <MDBox mb={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Order Summary
                </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    Subscription Name:
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="h4" fontWeight="medium">
                        {plan.name}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    Subscription Price:
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        €{plan.price.unit_amount/100}/mėn
                    </MDTypography>
                </MDBox>
            </MDBox>

            <MDBox display="flex" justifyContent="space-between" mt={3} mb={5}>
                <MDTypography variant="body1" fontWeight="light" color="text">
                    Total:
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body1" fontWeight="medium">
                        €{plan.price.unit_amount/100}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <PaymentElement options={{
                paymentMethodOrder: ['card', 'apple_pay']
            }}/>
            <MDButton className={'mt-3'} variant={'contained'} color={'warning'} type={'submit'} id="submit" onClick={()=>this?.submit?.()} fullWidth>Subscribe</MDButton>
            {errorMessage && <MDAlert color={'error'}>{errorMessage}</MDAlert>}
        </form>
    ):null
}

function PricingPage() {
    const [clientSecret, setClientSecret] = useState(null);
    const [plan, setPlan] = useState(null);
    const params = useParams();

    const getIntent = React.useCallback(async () => {
        const intent = await api.post('users/payment-intent', {plan: params.plan})
        if(intent?.latestInvoice?.paid) {
            setPlan(intent?.plan)
        } else {
            setClientSecret(intent?.clientSecret)
            setPlan(intent?.plan)
        }
    }, [params.plan])
    useEffect(() => {
        getIntent()
    }, [getIntent]);

    return plan?(
        <PageLayout>
                <Header plan={plan.name}>
                    <ExamplesNavbar/>
                    <Container>
                        {!clientSecret && plan.latestInvoice?.paid && <MDBox mt={5} pt={5} style={{textAlign: 'center'}}>
                            <h4>You were successfully subscribed to plan <b>"{plan.name}"</b></h4>
                            {plan.latestInvoice?.discount && <div className={'mt-3 mb-5'}>
                                Total price is <strong>€<FormattedNumber
                                value={plan.latestInvoice.total / 100} style={'decimal'} minimumFractionDigits={2} /></strong>
                                &nbsp;where original price is <strong>€<FormattedNumber
                                value={plan.price.unit_amount/100} style={'decimal'} minimumFractionDigits={2} /></strong>
                                &nbsp;and applied discount is <strong>{plan.latestInvoice.discount.coupon.percent_off}%</strong>
                            </div>}
                            <hr/>
                            <MDButton size={'large'} variant={'outlined'} color={'success'} onClick={()=>{
                                setTimeout(()=>document.location.href='/business/profile', 2000)
                            }}>Confirm Selection</MDButton>
                        </MDBox>}
                        <MDBox p={5} className={'m-auto'} style={{maxWidth: 800}}>

                            {clientSecret && <Elements stripe={stripePromise} options={{clientSecret}}>
                                <CheckoutForm params={params} plan={plan} setClientSecret={setClientSecret}/>
                                <MDBox mb={5}>&nbsp;</MDBox>
                            </Elements>}
                        </MDBox>
                    </Container>
                </Header>
            <Footer/>
        </PageLayout>
    ):null;
}

export default PricingPage;
