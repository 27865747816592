/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import _ from "lodash";
import React, {useCallback, useEffect, useRef, useState} from "react";
import GoogleMapReact from 'google-maps-react-markers';
import Marker from "components/Map/Marker";
import useSupercluster from "use-supercluster";
import MarkerCluster from "components/Map/MarkerCluster";
import {Popover} from "reactstrap";
import MasterCard from "views/examples/MasterCard";
import {useIntl} from "react-intl";

// reactstrap components

// core components


const defaultMapOptions = {
    clickableIcons: false,
    loading: 'async'
}

let onHold=false;
const MapHeader = ({mapRef, user, refCbk, filter, setSelected, scrollToSelected, events=[]}) => {
    //const [onHold, setOnHold] = useState(false);
    const [listener, setListener] = useState(false);
    const [center, setCenter] = useState(false);
    const [mapBounds, setMapBounds] = useState({
        bounds: [0, 0, 0, 0],
        zoom: 0,
    })
    const [points, setPoints] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const getPoints = async (lat,lng) => {
        return;
        const pRes = await fetch((document.location.hostname.indexOf('local')!==-1?'https://alexche.ngrok.app/api':'')+`/search/${lat}/${lng}`).then(r=>r.json()).catch(e=>{
            console.log(e)
        });
        if(!pRes) {
            setTimeout(()=>getPoints(lat,lng), 500)
        } else {
            setPoints([])
            setPoints(pRes||[])
        }
    }

    const loadData = async ()=>{
        //setLoading(true)
        //await getPoints(54.6927741, 25.2815682)
        //setLoading(false)
    }
    React.useEffect( ()=>{
        loadData()
    }, [])
    React.useEffect(()=>{
        if(center[0] && !loading)
        //getPoints(center[1],center[0])
        refCbk(filter)
    }, [center, filter])
    const onMapChange = _.debounce(({ bounds, zoom, center }) => {
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()
        setCenter(oldCenter=> {
            return (oldCenter?.[0]!==center[0] || oldCenter?.[1]!==center[1])?center:oldCenter
        })
        setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom })
    }, center?10:1000)


    let { clusters, supercluster } = useSupercluster({
        points: events.map(p=>({...p, geometry: p.locationData.geometry}))
            .map(p=>({uuid: p.uuid, rating: p.rating, name: p.name, geometry: p.geometry,

                minPrice: p.minPrice,
                maxPrice: p.maxPrice,
                photo: p.avatar}))
            .map(p => ({
            type: "Feature",
            properties: { cluster: false},
            geometry: {
                type: "Point",
                coordinates: [
                    parseFloat(p.geometry.location.lng),
                    parseFloat(p.geometry.location.lat)
                ]
            },
            rating: p.rating,
            minPrice: p.minPrice,
            maxPrice: p.maxPrice,
            photo: p.photo,
            place_id: p.uuid,
            uuid: p.uuid,
            name: p.name,
        })),
        bounds: mapBounds.bounds,
        zoom: mapBounds.zoom,
        options: { radius: 110 }
    });

    const [popoverOpen, setPopoverOpen] = React.useState('');
    const onMarkerClick = useCallback((index, e) => {
        onHold = true
        if(window.screen.width >= 600) {
            //scrollToSelected(index)
            setPopoverOpen(index)
            const shift = window.screen.width-e.clientX;
            console.log(shift, `[data-id="tooltip${index}"]`)
            /*if(shift<342) {
                setTimeout(()=> {
                    const el = document.querySelector(`[data-id="tooltip${index}"]`)?.parentElement;
                    const oldShift = parseFloat(window.getComputedStyle(el, null).getPropertyValue('transform').split(',')?.[4]||0)
                      el.style.transform = el.style.transform.replace(`translate3d(${oldShift}px`,`translate3d(${(window.screen.width-350)}px`)
                }, 100)
            }*/
        } else {
            setSelected(index)
            const el = document.getElementById(`place-${index}`);
            el?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }
        setTimeout(()=>onHold = false, 200)
    }, [])
    const onMapClick = useCallback(() => {
        if(!onHold)
            setPopoverOpen('')
    }, [onHold])

    React.useEffect(()=>{
        if(mapRef.current) {
            setListener(mapRef.current.addListener('click', onMapClick))
        }
        return ()=>onHold=false;
    }, [mapRef.current])

    const intl = useIntl();

  return (
      <>
          <div id={`tooltip`}></div>
          <div className={'map-wrapper'}>
              <GoogleMapReact
                  apiKey="AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4"
                  defaultCenter={{lat: 54.695, lng: 25.27}}
                  defaultZoom={12.75}
                  clickableIcons={false}
                  defaultOptions={defaultMapOptions}
                  options={{
                      ...defaultMapOptions,
                      gestureHandling: 'greedy',
                  }}
                  onGoogleApiLoaded={({map}) => {
                      mapRef.current = map;
                  }}
                  onChange={onMapChange}
              >
                  {(clusters || []).map((p, index) => {
                      const tooltipId = p.uuid;
                      return (p.properties.cluster ? <MarkerCluster
                              key={index}
                              lat={p.geometry.coordinates[1]}
                              lng={p.geometry.coordinates[0]}
                              markerId={p.name}
                              score={p.rating?p.rating:intl.formatMessage({id: "common.noreviews"})}
                              properties={p.properties}

                              onClick={() => {
                                  const expansionZoom = Math.min(
                                      supercluster.getClusterExpansionZoom(p.id),
                                      20
                                  );
                                  mapRef.current.setZoom(expansionZoom);
                                  mapRef.current.panTo({lat: p.geometry.coordinates[1], lng: p.geometry.coordinates[0]});
                              }} // you need to manage this prop on your Marker component!
                          /> :
                          <Marker
                              key={index}
                              lat={p.geometry.coordinates[1]}
                              lng={p.geometry.coordinates[0]}
                              markerId={tooltipId}
                              score={p.rating || 0}
                              onClick={onMarkerClick}
                          >
                              {document.getElementById(`tooltip${tooltipId}`) && <Popover
                                  className={'popover-place'}
                                  placement={window.innerWidth < 768 ? "top" : "right"}
                                  data-id={`tooltip${tooltipId}`}
                                  target={`tooltip${tooltipId}`}
                                  isOpen={document.getElementById(`tooltip${tooltipId}`) && popoverOpen === tooltipId}
                                  toggle={() => setPopoverOpen('')}
                              >
                                  <MasterCard
                                      user={user}
                                      coachId={p.uuid}
                                      image={p.photo}
                                      title={p.name}
                                      pricing={<>&euro;{p.minPrice} - &euro;{p.maxPrice}</>}
                                      description={p.description}
                                      rating={p.rating}
                                      location="Milan, Italy"
                                      action={null}
                                  />


                              </Popover>}
                          </Marker>
                  )})}


              </GoogleMapReact>
          </div>
      </>
  );
}

export default MapHeader;
