import { useIntl } from 'react-intl';
import {useLocale} from "../useLocale";
import {forIn, groupBy} from "lodash";



export function useDictionaries() {
    const {locale} = useLocale()
    const intl= useIntl()
    const statusesDict = [
        {
            status: "pending",
            color: "info",
            message: intl.formatMessage({id: "calendar.pending"}),
            label: intl.formatMessage({id: "calendar.pending"})
        },
        {
            status: "accepted",
            color: "success",
            message: intl.formatMessage({id: "calendar.accepted.message"}),
            label: intl.formatMessage({id: "calendar.accepted"})
        },
        {
            status: "declined",
            color: "error",
            message: intl.formatMessage({id: "calendar.declined"}),
            label: intl.formatMessage({id: "calendar.declined"})
        },
        {
            status: "canceled",
            hidden: true,
            color: "error",
            message: 'canceled by the client',
            label: intl.formatMessage({id: "calendar.canceled"})
        }
    ]

    const statusesMap = groupBy(statusesDict, 'status');
    forIn(statusesMap, (v, k)=>statusesMap[k]=v[0])

    return {
        statusesDict,
        statusesMap
    }
}
