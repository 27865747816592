import React, { useState, useEffect, useContext, createContext } from "react";
import {API} from "./api"
import Swal from "sweetalert2";
import {setCookie} from "./cookies";

const api = new API()

const authContext = createContext({user: null});

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  if(auth.user === null)
    return null;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const login = async (email, password, cb) => {
    const user = await api.put('users', {email, password}).catch(e=> {
      Swal.fire({
        title: 'Error!',
        text: 'Email or password are invalid',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
    if(user?.uuid) {
      setUser(user)
      setCookie('d-token',user.token)
      cb && cb(user)
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Email or password are invalid',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      setUser(false)
    }
  }
  const signin = async (email, phone, code, cb) => {
    const user = await api.put('users/verify', {email, phone, code}).catch(e=> {
      Swal.fire({
        title: 'Error!',
        text: 'Code is invalid',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
    if(user?.uuid) {
      setUser(user)
      setCookie('d-token',user.token)
      cb && cb(user)
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Code is invalid',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      setUser(false)
    }
  };
  const sendCode = async (body, cb) => {
    const res = await api.post('users/verify', body).catch(e=>{
      Swal.fire({
        title: 'Error!',
        text: 'Please provide correct email',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })

    cb && cb(res)
  };
  const fetchUser = async () => {
    const user = await api.get('users/me')
    try {
      user.dicts = {
        ...(user.dicts||{}),
        sports: await api.get('dict/sports')
      }
    } catch (e){

    }
    if(user?.uuid) {
      setUser(user)
    } else {
      setUser(false)
    }
  };
  const checkUser = async (email, phone) => {
    const {userFound} = await api.post('users/check', {email, phone})
    return userFound;
  };
  const signup = async (email, phone, name, surname, code, isBusiness = false, cb) => {
    const user = await api.post('users', {email, phone, name, surname, code, isBusiness}).catch(e=> {
      Swal.fire({
        title: 'Error!',
        text: 'Code is invalid',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
    if(user?.error) {
      setUser(false)
      return Swal.fire({
        title: 'Error!',
        text: 'Code is invalid',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
    if(user) {
      setUser(user)
      setCookie('d-token',user.token)
      cb && cb(user)
    } else {
      setUser(false)
    }
  };
  const signout = () => {
    setCookie('d-token', '')
    setUser(false)
    setTimeout(()=>document.location.reload(), 500)
  };
  const sendPasswordResetEmail = async (email) => {
    await api.post('users/reset', {email})
    Swal.fire({
      title: 'Sent!',
      text: 'Please check your email for further instructions',
      icon: 'success',
      confirmButtonText: 'OK'
    })
  };
  const confirmPasswordReset = async (hash, password, cbk) => {

    cbk(await api.post('users/reset/confirm', {hash, password}))
  };


  useEffect(( ) => {
   fetchUser()
  }, []);

  // Return the user object and auth methods
  return {
    user,
    fetchUser,
    checkUser,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendCode,
    login
  };
}
