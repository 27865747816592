/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
// reactstrap components
import {
    Label,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import HeaderMap from "components/Headers/HeaderMap.js";
import Slider from "nouislider";
import "nouislider/dist/nouislider.min.css";
import MasterCardListItem from "views/examples/MasterCardListItem";


import Card from "@mui/material/Card";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Save';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import PulseLoader from "react-spinners/PulseLoader";

import { Scrollbar } from 'react-scrollbars-custom';
import {API} from "api";
import _ from "lodash";
import {useAuth} from "useAuth";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import Select from "react-select";
import {FormattedMessage, useIntl} from "react-intl";
import StarIcon from "@mui/icons-material/Star";
import {Rating} from "react-simple-star-rating";
import selectData from "./account/settings/components/BasicInfo/data/selectData";
import FormField from "./account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import {capitalizeFirstLetter} from "./account/settings/components/BasicInfo";


const api = new API()

function SearchPage() {

    const intl = useIntl()
    const {user} = useAuth();
    const [loading, setLoading] = React.useState(true);
    const [priceSlider, setPriceSlider] = React.useState({
        priceMax: 200,
        priceMin: 0
    });
    const [filter, setFilter] = React.useState({
        minPrice: 10,
        maxPrice: 100,
    });
    const [selected, setSelectedState] = React.useState(null);
    const [uniqueActivities, setUniqueActivities] = React.useState([]);
    const [opacity, setOpacity] = React.useState(0);
    const mapRef = useRef();
    const scrollRef = useRef();
    const navigate = useNavigate();

    React.useEffect(() => {
        api.get('sessions/metadata').then((metadata = {
            priceMax: 200,
            priceMin: 0
        })=>{
            if(metadata.priceMin===metadata.priceMax) {
                metadata.priceMin = 0;
            }
            if(metadata.priceMax) {
                setFilterField('minPrice', metadata.priceMin)
                setFilterField('maxPrice', metadata.priceMax)
                setPriceSlider(metadata)
            }
        })
        if(user) {
            setUniqueActivities(user.dicts?.sports.map(s => ({name: s.name[intl.locale], id: s.key})))
        } else {
            api.get('dict/sports').then(sports=>setUniqueActivities(sports?.map(s => ({name: s.name[intl.locale], id: s.key}))||[]))
        }
    }, []);

    const setFilterField = (field, value) => {
        setFilter(f=>({...f, [field]: value}))
    }

    const [points, setPoints] = React.useState([]);

    const getPointsCall = React.useCallback(
        _.debounce(async (lat,lng, filter) =>{
            setLoading(true)
            const pRes = await api.post(`sessions/search`, {lat, lng, bounds: {
                    southwest: mapRef.current.getBounds({asGeoJSON: true}).getSouthWest(),
                    northeast: mapRef.current.getBounds({asGeoJSON: true}).getNorthEast(),
                }, filter});
            if(!pRes) {
                setTimeout(()=>getPoints(lat,lng), 500)
            } else {
                setPoints([])
                setPoints(pRes.map(p=>({...p, geometry: p.locationData.geometry, rating: p.rating}))||[])
                setLoading(false)
            }
        }, 1000),
        []
    );

    const getPoints = React.useCallback(
        async (lat,lng) => getPointsCall(lat,lng,filter),
        [filter, getPointsCall])

    document.documentElement.classList.remove("nav-open");
    const setSelected = (v) => {
        setSelectedState(v);
    }
    const refCbk = React.useCallback(async (filter) => {
        if (mapRef.current) {
            const loc = mapRef.current.center
            if(loc.lat())
            await getPoints(loc.lat(), loc.lng())
        }
    }, [mapRef, getPoints]);
    const handleClickBook = React.useCallback((coachId) => {
        const path = true?`/coach-profile/${coachId}`:'/client/signup';
        navigate(path)
    }, [user]);
    React.useEffect(() => {
        refCbk(filter)
    }, [filter, refCbk]);

    React.useEffect(() => {
        if (
            !document.getElementById("sliderDouble").classList.contains("noUi-target")
        ) {
            Slider.create(document.getElementById("sliderDouble"), {
                start: [filter.minPrice, filter.maxPrice],
                connect: true,
                step: 1,

                range: {min: priceSlider.priceMin, max: priceSlider.priceMax},
                tooltips: [
                    {to: v => '€️' + v.toFixed()},
                    {to: v => '€️' + v.toFixed()}
                ],
            }).on('end', (values)=>{
                console.log(values, filter, parseInt(values[0]))
                setFilterField('minPrice', parseInt(values[0]))
                setFilterField('maxPrice', parseInt(values[1]))
            });
        } else if(document.getElementById("sliderDouble") && document.getElementById("sliderDouble").noUiSlider) {
            document.getElementById("sliderDouble").noUiSlider.updateOptions({
                start: [filter.minPrice, filter.maxPrice],
                range:{
                    min: priceSlider.priceMin,
                    max: priceSlider.priceMax,
                }
            })
            document.getElementById("sliderDouble").noUiSlider.set([filter.minPrice, filter.maxPrice])
        }
    }, [setFilterField, priceSlider, filter]);

    const [inUse, setInUse] = useState();

    const [filterOpen, setFilterOpen] = useState(false)

    const scrollToSelected = (index) => {
        const sb = document.querySelector(`.ScrollbarsCustom-Wrapper`);
        const el = document.getElementById(`place-${index}`);
        const filterEl = document.querySelector(`.filter-card`);
        if(sb && el) {
            const bounds = el.getBoundingClientRect();
            if(window.screen.width < 700) {
                console.log({
                    'window.screen.width < 700':window.screen.width < 700,
                    filterOpen,
                    'window.screen.width':window.screen.width,
                    'sb.offsetTop':sb.offsetTop,
                    'sb.getBoundingClientRect()':sb.getBoundingClientRect(),
                    'el.offsetTop':  el.offsetTop,
                    'filterEl.getBoundingClientRect()': filterEl.getBoundingClientRect(),
                    '(filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop)': (filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop)
                })
                window.scrollTo(0, filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop);
                scrollRef.current.scrollTo(0, el.offsetTop)
            }
            if(window.screen.width >= 700) {
                console.log({
                    'window.screen.width >= 700':window.screen.width >= 700,
                    filterOpen,
                    'window.screen.width':window.screen.width,
                    'sb.offsetTop':sb.offsetTop,
                    'sb.getBoundingClientRect()':sb.getBoundingClientRect(),
                    'el.offsetTop':  el.offsetTop,
                    'filterEl.getBoundingClientRect()': filterEl.getBoundingClientRect(),
                    '(filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop)': (filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop) +300 + filterEl.getBoundingClientRect().top
                })
                scrollRef.current.scrollTo(0, el.offsetTop+sb.offsetTop - 0.33* el.getBoundingClientRect().height)
               window.scrollTo(0, el.offsetTop+sb.offsetTop - 0.33* el.getBoundingClientRect().height);
            }
        }
    }


    return (
        <>
            <ExamplesNavbar/>
            <HeaderMap user={user} filter={filter} mapRef={mapRef} events={points} refCbk={refCbk} setSelected={setSelected} scrollToSelected={scrollToSelected}/>
            <Container fluid={'xl'} className={'container-search'}>
                <Row>
                    <Col  lg={4} md={6} className={'pt-3 pt-lg-0'}>
                        <Card className="p-4 mt-md-n5 ml-lg-1 ml-xl-5 filter-card">
                            <MDButton id={'filter-button'} variant={'gradient'} color={'violet'} className={`d-md-none p-0`} onClick={()=>setFilterOpen(f=>!f)}>
                               <FilterAltIcon className={'mr-1'}/>
                              <MDTypography variant={'h6'} color={'white'}><FormattedMessage id={"search.filter"}/></MDTypography>
                            </MDButton>
                            <h6 className=" mx-auto mt-n2 d-none d-md-block"><FormattedMessage id={"search.filter"}/></h6>

                            <div className={filterOpen ? 'd-md-block' : `d-none d-md-block`}>

                                <div className="title pb-4">
                                    <strong><FormattedMessage id={"search.priceRange"}/></strong>
                                </div>
                                <div className={'p-3'}>
                                    <div className="slider slider-warning" id="sliderDouble"/>
                                </div>
                                <div className="title">
                                    <strong><FormattedMessage id={"search.sport"}/></strong>
                                </div>
                                <div>
                                    <Select
                                        placeholder={intl.formatMessage({id: 'common.any'})}
                                        styles={{menu: (baseStyles, state) => ({...baseStyles, zIndex: 9999999999})}}
                                        options={[{
                                            value: null,
                                            label: `---${intl.formatMessage({id: 'common.forms.any'})}---`
                                        }, ...uniqueActivities.map(a => ({label: a.name, value: a.id}))]}

                                        onChange={(v) => {
                                            setFilter(f => ({
                                                ...f,
                                                activity: v?.value || null
                                            }))
                                        }}/>
                                </div>
                                <div className="title">
                                    <strong><FormattedMessage id={"search.language"}/></strong>
                                </div>
                                <div>
                                    <Select
                                        placeholder={intl.formatMessage({id: 'common.any'})}
                                        styles={{menu: (baseStyles, state) => ({...baseStyles, zIndex: 9999999999})}}
                                        options={[{
                                            value: null,
                                            label: `---${intl.formatMessage({id: 'common.forms.any'})}---`
                                        }, ...selectData.languages.map(a => ({label: a, value: a}))]}

                                        onChange={(v) => {
                                            setFilter(f => ({
                                                ...f,
                                                language: v?.value || null
                                            }))
                                        }}/>

                                </div>
                                <div className="title">
                                    <strong><FormattedMessage id={"forms.special.type"}/></strong>
                                </div>
                                <FormGroup check>
                                    <Label check className={'label-small'}>
                                        <Input
                                            value={filter.individual}
                                            onChange={(e) => setFilterField('individual', e.target.checked)}
                                            type="checkbox"/>
                                        <FormattedMessage id={"forms.special.type.individual"}/> <span
                                        className="form-check-sign"/>
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check className={'label-small'}>
                                        <Input
                                            value={filter.group}
                                            onChange={(e) => setFilterField('group', e.target.checked)}
                                            type="checkbox"/>
                                        <FormattedMessage id={"forms.special.type.group"}/> <span
                                        className="form-check-sign"/>
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check className={'label-small'}>
                                        <Input
                                            value={filter.special}
                                            onChange={(e) => setFilterField('special', e.target.checked)}
                                            type="checkbox"/>
                                        <FormattedMessage id={"forms.special.type.special"}/> <span
                                        className="form-check-sign"/>
                                    </Label>
                                </FormGroup>

                                <div className="title">
                                    <strong><FormattedMessage id={"forms.special.benefits"}/> </strong>
                                </div>
                                <FormGroup check>
                                    <Label check className={'label-small'}>
                                        <Input
                                            value={filter.food}
                                            onChange={(e) => setFilterField('food', e.target.checked)}
                                            type="checkbox"/>
                                        <FormattedMessage id={"forms.special.benefits.food"}/> <span
                                        className="form-check-sign"/>
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check className={'label-small'}>
                                        <Input
                                            value={filter.sports}
                                            onChange={(e) => setFilterField('sports', e.target.checked)}
                                            type="checkbox"/>
                                        <FormattedMessage id={"forms.special.benefits.programs"}/> <span
                                        className="form-check-sign"/>
                                    </Label>
                                </FormGroup>

                                <div className="title">
                                    <strong><FormattedMessage id={"search.rating"}/></strong>
                                </div>
                                <FormGroup>
                                    <FormGroup check>
                                        <Label check className={'label-small'}>
                                            <Input
                                                value={filter.sports}
                                                onChange={(e) => setFilterField('onlyWithReviews', e.target.checked)}
                                                type="checkbox"/>
                                            <FormattedMessage id={"search.onlyWithReviews"}/> <span
                                            className="form-check-sign"/>
                                        </Label>
                                    </FormGroup>
                                    <small><FormattedMessage id={"search.rating.min"}/></small>
                                    <Rating
                                        onClick={v => setFilterField('minRating', v)}
                                    /> {filter.minRating ? <strong className={'text-success'}>{filter.minRating}+</strong> : null}
                                </FormGroup>

                                <div className="title">
                                    <strong><FormattedMessage id={"search.other"}/></strong>
                                </div>
                                <FormGroup>
                                    <MDInput type="text"
                                             label={`${intl.formatMessage({id: "common.name"})}, ${intl.formatMessage({id: "common.surname"})}`}
                                             name={'name'} fullWidth
                                             value={filter.name}
                                             onChange={e => setFilterField('name', e.target.value)}/>
                                </FormGroup>

                            </div>
                            {filterOpen && <MDButton variant={'gradient'} color={'violet'} className={`d-md-none p-0`}
                                                     onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                setFilterOpen(false)
                            }}>
                                <CloseIcon className={'mr-1'}/>
                                <MDTypography variant={'h6'} color={'white'}>Save Filter</MDTypography>
                            </MDButton>}
                        </Card>
                    </Col>
                    <Col xl={1} hidden={'lg'} style={{border:'solid 1px red'}}>&nbsp;</Col>

                    <Col lg={8} md="6" className={'p-lg-0 p-xl-2 mt-3 mt-md-0'}>

                        <Scrollbar
                            ref={scrollRef}
                            noScrollX={true}
                            trackYProps={{style:{opacity}}}
                            onScrollStart={(() => {
                                setInUse(true)
                                setOpacity(1)
                            })}
                            onScrollStop={(() => {
                                for (let i=0; i<=20; i++) {
                                    setTimeout(() => {
                                        if(inUse) {
                                            setOpacity(o=>o-0.05)
                                        }
                                    }, 10 * i);
                                }
                                setInUse(false)
                            })}
                            onScroll={(scrollValues)=>{
                            if(scrollValues.scrollTop<1) {
                                window.scrollBy(0,-100)
                            }
                        }} style={{ height: '100vh', paddingRight: 5 }} >
                        {loading && <Container fluid={true} className={'p-5 text-center min-vh-100'}  ><PulseLoader color={'#9180ff'} size={18}/></Container>}
                            {!loading && <Row className={'pr-1'}>{points.length<1 && <Container fluid={true} className={'p-5 text-center'} >Nothing found, try adjusting filter or zoom out the map.</Container>}
                            {points.map(p => ({
                                ...p,
                                uuid: p.uuid,
                                place_id: p.uuid,
                                rating: p.rating,
                                name: p.name,
                                description: p.description,
                                geometry: p.geometry,
                                photo: p.photos ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${p.photos?.[0]?.photo_reference}&key=AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4` : null
                            }))
                                .map((p, index) => (
                                    <Col lg={'6'} sm={'12'} className={'p-3 master-card-wrapper'} key={p.uuid} id={'place-'+p.uuid} >
                                        <MasterCardListItem
                                            isTop={index<2}
                                            image={p.avatar}
                                            title={p.name}
                                            uuid={p.uuid}
                                            scrollToSelected={scrollToSelected}
                                            handleClickBook={handleClickBook}
                                            pricing={(p.maxPrice && p.maxPrice>p.minPrice)?(<>Nuo &euro;{p.minPrice} iki &euro;{p.maxPrice}</>):(<>&euro;{p.minPrice}</>)}
                                            rating={p.rating}
                                            coachData={p.coachData}
                                            description={(<MDBox>
                                                    <MDBox className={'only-selected '}>
                                                        <MDBadge color={'success'} className={'badge-card-marker mt-1 mb-2'} badgeContent={
                                                            <MDBox width={'100%'} display="flex" justifyContent="space-between"
                                                                   alignItems="space-between">
                                                                <MDTypography variant="h6" className={'h6-bottom'} color="white" fontWeight="medium">
                                                                    <StarIcon color={'gold'} style={{marginTop: -4}}
                                                                              fontSize={'small'}/> {p.rating?parseFloat(p.rating).toFixed(1): intl.formatMessage({id: "common.noreviews"})}
                                                                </MDTypography>
                                                            </MDBox>
                                                        } container/>
                                                    </MDBox>
                                                    <MDTypography variant={'caption'}>{p.summary}</MDTypography>
                                            </MDBox>)}
                                            action={null}
                                            isLoggedIn={true}
                                            selected={selected===p.uuid}
                                            setSelected={setSelected}
                                        />
                                    </Col>
                                ))}
                        </Row>}
                            {points.length>0 ? (<Row className={'pr-1'}>
                                <Col lg={'6'} sm={'12'} style={{height: 500}} key={'place-last'} id={'place-last'} />
                            </Row>):''}
                        </Scrollbar>

                    </Col>
                </Row>
            </Container>


            <DemoFooter/>
        </>
    );
}

export default SearchPage;
