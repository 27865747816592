import { getCookie } from './cookies'

export const apiURLMap = {
  development: 'https://dev.spotya.lt/api',
  production: '/api',
  local: 'http://localhost:8000/api',
}

export class API {
  get baseURL() {
    if(!process.env.REACT_APP_ENV) {
      process.env.REACT_APP_ENV = process.env.NODE_ENV
    }
    return apiURLMap[process.env.REACT_APP_ENV] || apiURLMap.production//process.env.NODE_ENV==='development'?`https://dev.spotya.lt/api`:`/api`
  }

  async put(path, body) {
    try {
      return await fetch(`${this.baseURL}/${path}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie('d-token')}`,
        },
        body: JSON.stringify(body)
      }).then(r => r.json())
    } catch (e) {
      console.log(e)
    }
  }

  async post(path, body) {
    return await fetch( `${this.baseURL}/${path}`,{
      method:"POST",
      headers: {
        Accept:"application/json",
        "Content-Type":"application/json",
        Authorization: `Bearer ${getCookie('d-token')}`,
      },
      body: JSON.stringify(body)}).then(r=>r.json())
  }

  async upload(path, file) {
    const data = new FormData()
    data.append('file', file)
    return await fetch( `${this.baseURL}/${path}`,{
      method:"POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie('d-token')}`,
      },
      body: data}).then(r=>r.json())
  }

  async get(path) {
    try {
      return await fetch(`${this.baseURL}/${path}`,{
        method:"GET",
        headers: {
          Accept:"application/json",
          "Content-Type":"application/json",
          Authorization: `Bearer ${getCookie('d-token')}`
        }}).then(r=>r.json())
    } catch (e) {
      console.log(e)
    }
  }
  async getFile(path, name) {
    try {
      return await fetch(`${this.baseURL}/${path}`,{
        method:"GET",
        headers: {
          Accept:"application/json",
          "Content-Type":"application/json",
          Authorization: `Bearer ${getCookie('d-token')}`
        }}).then( res => res.blob() )
    } catch (e) {
      console.log(e)
    }
  }
  async delete(path) {
    return await fetch(`${this.baseURL}/${path}`,{
      method:"DELETE",
      headers: {
        Accept:"application/json",
        "Content-Type":"application/json",
        Authorization: `Bearer ${getCookie('d-token')}`
      }}).then(r=>r.json())
  }

}
