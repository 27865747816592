/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useCallback, useMemo, useState} from "react";
import _ from "lodash";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Swal2 from "sweetalert2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDDropzone from "components/MDDropzone";
import SectionCarousel from "views/index-sections/SectionCarousel";

import {Container} from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";

import ProductImages from "views/examples/product-page/components/ProductImages";

import {API} from "api";
import {FormattedMessage} from "react-intl";
import ReactPlayer from 'react-player'
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const api = new API()

function Videos({form, fieldName, setFormField, updateUser}) {


  const addVideo = useCallback(async () => {
      const { value: url } = await Swal2.fire({
          input: "url",
          inputLabel: "URL address",
          inputPlaceholder: "Enter the URL"
      });
      if (url) {
          const urls = [...(form.coachData[fieldName] || []), url]
          form.coachData[fieldName]=urls;
          setFormField(`coachData.${fieldName}`, form.coachData[fieldName])
          await updateUser(['coachData'])
      }
  }, [form]);
  const deleteCbk = useCallback(async (url) => {
      Swal2.fire({
          title: 'Do you want to delete this video?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
      }).then(async (result) => {
        if(result.isConfirmed) {
            console.log(url, form.coachData[fieldName], _.remove(form.coachData[fieldName], d=>d===url))
            const urls = form.coachData[fieldName].filter(d=>d!==url);
            form.coachData[fieldName]=urls;
            setFormField(`coachData.${fieldName}`, form.coachData[fieldName])
            await updateUser(['coachData'])
        }
      })
  }, [form])

    const VideoCard = ({url})=>{
        return <Grid item xs={6} style={{padding: 8, position: 'relative'}}>
            <ReactPlayer width={'100%'} url={url} controls={true}  fallback={<p>Incorrect Url</p>} />
            <MDButton className={'del-img-btn '} style={{
                position: 'absolute',
                top: 2,
                right: 2,
                opacity: 0.9
            }} iconOnly size={'small'} variant={'gradient'} color={'error'} onClick={()=>deleteCbk(url)}><DeleteForeverIcon fontSize={'large'}/></MDButton>
        </Grid>
    }

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} >
          <Grid item>
              <MDTypography variant={'h5'} color={'dark'}><FormattedMessage id={`forms.${fieldName}`}/></MDTypography>
          </Grid>
          <Grid item xs={12}>
              <Grid container>
              {form.coachData[fieldName]?.length > 0 ? form.coachData[fieldName].map(url=>(<VideoCard key={url} url={url} deleteCbk={()=>deleteCbk(url)}/>)) : <MDTypography className={'text-center'} component={'div'} variant={'subtitle2'} color={'warning'}><FormattedMessage id={'common.videos.no'}/></MDTypography>}
              </Grid>
          </Grid>
          <Grid item xs={12}><MDButton style={{float: 'right'}} onClick={addVideo} variant={'contained'} color={'success'}><FormattedMessage id={`forms.${fieldName}.add`}/></MDButton></Grid>

        </Grid>
      </MDBox>
    </Card>
  );
}

export default Videos;
