import React from 'react'

import MDBox from "components/MDBox";

const Marker = ({
                    className,
                    lat,
                    lng,
                    markerId,
                    onClick,
                    draggable,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onDrag,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onDragEnd,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onDragStart,
                    score,
                    properties,
                    ...props
                }) => {
    return lat && lng ? (
        <div className={'clusterMarker'} onClick={()=>onClick()}>
            <div className={'clusterMarker-title'}>{properties.point_count}</div>
        </div>
    ) : null
}

export default Marker
