/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props


// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import StarIcon from '@mui/icons-material/Star';
import React from "react";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import {FormattedMessage} from "react-intl";

function MasterCard({image, title, pricing, rating, setSelected, selected, scrollToSelected, isLoggedIn, description, isTop, uuid, handleClickBook, coachData}) {

    return (
        <Card
            onMouseOver={()=>{
                setSelected(null)
            }}
            onClick={()=>{
                scrollToSelected(uuid)
            }}
            className={`card-master-2 m-auto ${isTop?'top-card':''} ${selected ? 'selected':''}`}
            sx={{
                ".card-header": {
                    backgroundImage1: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundColor: 'transparent',
                },
                "&:hover .card-header": {
                    zIndex: 1000,
                    transformStyle: 'flat',
                    transform: "translate3d(0, -33%, 0)",
                },
                "&:hover .MuiAvatar-rounded": {
                    zIndex: 1000,
                    transformStyle: 'flat',
                    transform: "translate3d(0, -33%, 0)",
                },
                "&:hover": {
                  marginTop: {
                      xs: 'calc(29.7% - 8px) !important',
                      md: '0 !important',
                  }
                },
                "&.selected .card-header": {
                    zIndex: 1000,
                    transformStyle: 'flat',
                    transform: "translate3d(0, -33%, 0)",
                },
            }}
        >
            <MDAvatar src={image} alt="profile-image"  size="x-full" shadow="sm" variant={'rounded'} sizings={{

            }}
            style={{
                transition: "transform 600ms cubic-bezier(0.34, 1.61, 0.7, 1)",
                height: 'calc(100% - 50px)',
                width: 'calc(100% - 16px)',
                marginLeft: 8,
                marginRight: 8,
                marginTop: -16,
                position: 'absolute',
            }}
            />
            <MDBox
                position="absolute"
                borderRadius="lg"
                mt={-2}
                mx={1}
                className="card-header"
                sx={{
                    borderRadius: '0.5rem',
                    height: 'calc(100% - 50px)',
                    width: 'calc(100% - 16px)',
                    transition: "transform 600ms cubic-bezier(0.34, 1.61, 0.7, 1)"
                }}
            >
                <MDBox
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    className="card-header-backdrop-image"
                />
                <MDBox
                    onTouchStart={(e)=>{
                        e.target.startTop = e.target.getBoundingClientRect().top
                    }}
                    onTouchEnd={(e)=>{
                        if(e.target.startTop === e.target.getBoundingClientRect().top) {
                            if(window.screen.width < 700) {
                                scrollToSelected(uuid);
                            }
                        }
                    }}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    zIndex={1}
                    sx={({palette: {gradients}, functions: {linearGradient}, boxShadows: {xl}}) => ({
                        background: selected?'linear-gradient(180deg, #42424a3d, rgba(182, 171, 249, 0.25))':'linear-gradient(180deg, rgba(66, 66, 74, 0.2), rgba(66, 66, 74, 0.4),rgba(66, 66, 74, 0.67), rgba(33, 33, 37, 1))',
                        boxShadow: selected?'inset 0 0 30px 15px #B6ABF9':xl,
                        borderRadius: '0.75rem'
                    })}
                />
                <MDBox sx={{left: 10}} textAlign={'left'}>
                    <MDBadge className={'badge-card-marker'} color={'violet'} badgeContent={
                        <MDTypography variant="h6" color="white" fontWeight="medium">
                            {pricing || '25$ - 50$'}
                        </MDTypography>
                    } container/>
                </MDBox>
                <MDBox  position="absolute"
                        width="100%"
                        bottom={0}
                        left={0}
                        zIndex={2} p={2}>
                    <MDBox width={'100%'} display="flex" justifyContent="space-between" alignItems="space-between">
                        <MDTypography style={{marginTop: 5, maxWidth: '80%', textOverflow: 'ellipsis'}}
                                      className={"text-nowrap overflow-hidden"} variant="h6" color="white"
                                      fontWeight="medium">
                            {title}
                        </MDTypography>
                        {/*<MDBadge color={'success'} className={'badge-card-marker'} badgeContent={
                            <MDBox width={'100%'} display="flex" justifyContent="space-between"
                                   alignItems="space-between">
                                <MDTypography variant="h6" className={'h6-bottom'} color="white" fontWeight="medium">
                                    <StarIcon color={'gold'} style={{marginTop: -4}}
                                              fontSize={'small'}/> {parseFloat(rating).toFixed(1)}
                                </MDTypography>
                            </MDBox>
                        } container/>*/}
                    </MDBox>
                </MDBox>
                <MDBox position="absolute" top={10} right={10} sx={{width: '50%'}} textAlign={'right'}>
                    <div className={'one-line'} style={{height: 28}}>
                        {coachData?.activities?.map(act=>(<MDBadge sx={{'>span':{marginLeft: '3px !important'}}} key={act.name} badgeContent={act.name} />))}</div>
                    <div className={'one-line'} style={{height: 28}}>
                        {coachData?.locations?.map(act => (
                            <MDBadge sx={{'>span':{marginLeft: '3px !important'}}} key={act.name} color={'dark'} badgeContent={act.name}/>))}</div>
                </MDBox>
            </MDBox>
            <MDBox position="relative" zIndex={0} p={2} height={'100%'}>

                <MDBox className={'bottom-line no2'} width={'calc(100% - 33px)'} >
                    <MDTypography variant={'caption'} className={'pt-2 card-desc'}>
                        {description}
                    </MDTypography>
                </MDBox>
                <MDBox className={'cta-master-2-container'}  >
                    <MDButton fullWidth variant={'gradient'} onClick={()=>handleClickBook(uuid)} className="cta-master-2 mx-auto" color="success">
                        {isLoggedIn?<FormattedMessage id={"search.signup.logged"}/>:<FormattedMessage id={"search.signup"} />}
                    </MDButton>
                </MDBox>
            </MDBox>
            <MDBox
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                className="card-header-backdrop"
            />
        </Card>
    );
}


export default MasterCard;
